import { useAuthContext } from 'noddi-provider';
import { ApiErrorMessageAdmin, LoadingScreen, NoddiDropdown, VerticalLayout } from 'noddi-ui';
import { Navigate, useNavigate } from 'react-router-dom';

import { noddiAsync, URLKeys } from 'noddi-async';
import { facilityRoutes } from 'noddi-util';
import { getNavItems } from '../../NavItems';
import { useStorageFacility, useStorageFacilityActions } from '../StorageFacilityStore';

export const HomePrivateLayout = () => {
  const { isLoggedIn, isTokenLoadingOnMount } = useAuthContext();
  if (isTokenLoadingOnMount) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={facilityRoutes.login.getBasePath()} />;
  }
  return <HomePrivateLayoutContent />;
};

const HomePrivateLayoutContent = () => {
  const navItems = getNavItems();
  const navigate = useNavigate();

  const {
    data: storageFacility,
    isPending: isStorageFacilityPending,
    error: storageFacilityError
  } = noddiAsync.useGet({
    type: URLKeys.getStorageFacilities
  });

  const { setStorageFacility } = useStorageFacilityActions();
  const { storageFacilityId } = useStorageFacility();

  if (storageFacilityError) {
    return <ApiErrorMessageAdmin error={[storageFacilityError]} />;
  }

  if (isStorageFacilityPending) {
    return <LoadingScreen />;
  }

  const storageFacilityOptions = storageFacility.map((facility) => ({
    value: facility.id,
    label: facility.name
  }));

  const selectedStorageFacilityOption = storageFacilityOptions.find((option) => option.value === storageFacilityId);

  return (
    <VerticalLayout
      disableUserGroupSelection
      CustomerHeaderElement={
        <NoddiDropdown
          options={storageFacilityOptions}
          onSelect={(item) => {
            setStorageFacility({ label: item.label, storageFacilityId: item.value });
            navigate(facilityRoutes.home.getBasePath());
          }}
          selectedOption={selectedStorageFacilityOption}
          placeholder='Select storage facility'
          className='!text-3'
        />
      }
      translations={{ profile: 'My account', logout: 'Log out' }}
      navItems={navItems}
    />
  );
};
