import { noddiAsync, URLKeys } from 'noddi-async';
import { StorageAreaOverview } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, NoddiBasicCard } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { BaseQRCodeDisplay } from '../../components/BaseQRCodeDisplay';
import { useStorageFacility } from '../../StorageFacilityStore';

export const StorageAreaPrintingList = () => {
  const { storageFacilityId } = useStorageFacility();
  const [storageAreaToPrint, setStorageAreaToPrint] = useState<StorageAreaOverview | null>(null);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const {
    data: storageAreas,
    isPending: isStorageAreasPending,
    error: storageAreasError
  } = noddiAsync.useGet({
    type: URLKeys.getStorageAreasOverview,
    input: { storageFacilityIds: [storageFacilityId] }
  });

  if (storageAreasError) {
    return <ErrorPage apiError={storageAreasError} />;
  }
  if (isStorageAreasPending) {
    return <LoadingScreen />;
  }

  return (
    <div className='mt-8 flex flex-col gap-4'>
      <p className='font-bold text-5'>Storage areas</p>
      <div className='grid grid-cols-2 gap-4'>
        {storageAreas.map((storageArea) => (
          <NoddiBasicCard key={storageArea.id}>
            <div className='flex items-center justify-between'>
              <p className='mb-2 text-5'>{storageArea.name}</p>
              <NoddiButton
                variant='secondary'
                onPress={() => {
                  setStorageAreaToPrint(storageArea);
                  setTimeout(() => {
                    handlePrint();
                  }, 400);
                }}
              >
                Print
              </NoddiButton>
            </div>
            <BaseQRCodeDisplay value={storageArea.identifier} size={100} />
          </NoddiBasicCard>
        ))}
      </div>

      <div className='hidden'>
        <div ref={componentRef}>
          <div>
            <div key={storageAreaToPrint?.id}>
              <div className='flex min-h-screen flex-col items-center justify-center gap-8'>
                <h3 className='font-bold text-20'>{storageAreaToPrint?.name}</h3>
                <BaseQRCodeDisplay size={1500} value={storageAreaToPrint?.identifier ?? ''} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
