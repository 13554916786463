import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import queryString from 'query-string';
import { Nullable } from 'vitest';

import { InputDataOrVoid } from '../NoddiAsync';
import {
  AddressOutput,
  AvailableBookingTimeWindowsByDateNew,
  AvailableBookingTimeWindowsInput,
  AvailableSalesItemsForBooking,
  BookingAddress,
  BookingListItem,
  BookingReceipt,
  BookingSMS,
  BookingSMSCreateInput,
  BookingStatusDto,
  Brand,
  Capability,
  CapabilityCreateInput,
  CapabilityListItem,
  CapabilityUpdateInput,
  CapacityContributionDetails,
  CarDetailType,
  CarGeneration,
  CarGenerationCreateInput,
  CarGenerationListInput,
  CarGenerationUpdateInput,
  CarListItem,
  CarMake,
  CarMakeCreateInput,
  CarMakeUpdateInput,
  CarModel,
  CarModelCreateInput,
  CarModelUpdateInput,
  CarPostInputType,
  CarWheelPosition,
  CarWheelSetSpecifications,
  CarWheelSetSpecificationsCreateInput,
  CarWheelSetSpecificationsListInput,
  CarWheelSetSpecificationsNew,
  CarWheelSetSpecificationsPatchBulkUpdateInput,
  CarWheelSetSpecificationsUpdateInput,
  CarWheelSetType,
  CreateCapacityContributionDto,
  EditBookingPermissions,
  EditBookingTimeWindowData,
  GroupAdminType,
  Invoice,
  InvoiceCreateInput,
  InvoiceListItem,
  InvoiceUpdateInput,
  Language,
  LicensePlateInfo,
  LicensePlateNumberData,
  MembershipDiscount,
  MembershipDiscountCreateInput,
  MembershipDiscountListItem,
  MembershipDiscountUpdateInput,
  MembershipProgram,
  MembershipProgramCreateInput,
  MembershipProgramListItem,
  MembershipProgramUpdateInput,
  OptionsTypeString,
  PaginatedInput,
  PaginatedResponse,
  PostCreateBooking,
  PostUserInput,
  PostUserWithOrganizationInput,
  PublicCoupon,
  RouteItem,
  SalesItem,
  SalesItemTag,
  SalesItemV2,
  SelectedSalesItemsCarGrouped,
  Skill,
  SkillListItem,
  SkillPatchInput,
  SkillPostInput,
  StorageUnit,
  StorageUnitInput,
  TierHotelPickupSalesItemOption,
  UnavailableSalesItem,
  UpdateCapacityContributionDto,
  UserData,
  UserDataProps,
  UserGroupAddress,
  UserGroupBooking,
  UserGroupsType,
  UserGroupType,
  UserListItem,
  UserType
} from '../types';
import { RearrangeRouteItem } from '../types/adminapp/route/rearrangeRoutes';
import { AddressForNewBooking, AddressSuggestion } from '../types/customerapp/booking/shared';
import { DeliveryNotificationRequestInput } from '../types/shared/notifications';
import { CarWheelSetToPickUp, CarWheelSetToPickUpInput } from '../types/shared/pickUpList';
import { CarTireQuoteFilters, TireOfferMinimal, TireOfferV2 } from '../types/shared/quotes';
import { CarWheelSetForLabelPrint, RouteLabel, RouteLabelInput } from '../types/workerapp/routeLabels';
import { convertObjectKeysToSnakeCase, downloadToExcel } from '../utils';
import { AdminServerRequests, AdminURLKeys, AdminURLKeysType, getAdminUrls } from './adminResources';
import { apiVersion, FetchMethods, ResponseType } from './commonResourceTypes';
import { CustomerServerRequests, CustomerURLKeys, CustomerURLKeysType, getCustomerUrls } from './customerResources';

import { Interactions, SMS } from '../types/shared/interactions';
import { AutoRecallSuggestion } from '../types/shared/recall';
import { TireMeasurementFilters } from '../types/shared/tireMeasurement';
import { getWorkerUrls, WorkerServerRequests, WorkerURLKeys, WorkerURLKeysType } from './workerResources';

export function getNoddiUrl<Type extends URLKeysTypes>({
  queryParams,
  path
}: {
  queryParams?: Partial<InputData<Type>>;
  path: string;
}): string {
  // filter out empty params
  const filteredQueryParams = Object.entries(queryParams || {}).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>
  );

  const _queryParams = queryString.stringify(convertObjectKeysToSnakeCase(filteredQueryParams || {}), {
    arrayFormat: 'none'
  });

  const queryParamString = _queryParams ? `?${_queryParams}` : '';

  return `/${path}${queryParamString}`;
}

// Base urls that are used across multiple apps
const BaseUrlKeys = {
  getPaginatedCapabilities: 'getPaginatedCapabilities',
  getCapabilities: 'getCapabilities',
  postCapability: 'postCapability',
  patchCapability: 'patchCapability',
  getCapability: 'getCapability',
  getTimeZones: 'getTimeZones',
  getServiceOrganizations: 'getServiceOrganizations',
  getBookingCategoriesForNewBooking: 'getBookingCategoriesForNewBooking',
  getRouteItemStatusById: 'getRouteItemStatusById',
  postTokenLogin: 'postTokenLogin',
  getPaginatedSalesItemTags: 'getPaginatedSalesItemTags',
  postSalesItemTag: 'postSalesItemTag',
  patchSalesItemTag: 'patchSalesItemTag',
  postVerifyPhoneNumber: 'postVerifyPhoneNumber',
  postUser: 'postUser',
  postDeliveryNotificationRequest: 'postDeliveryNotificationRequest',
  postEmailLogin: 'postEmailLogin',
  getPhoneNumberVerification: 'getPhoneNumberVerification',
  getPaginatedUsers: 'getPaginatedUsers',
  getUser: 'getUser',
  getGroupsOfUser: 'getGroupsOfUser',
  getDataFromLicensePlateNumber: 'getDataFromLicensePlateNumber',
  getCarFromLicensePlateNumber: 'getCarFromLicensePlateNumber',
  getTierHotelPickupSalesItemsOptions: 'getTierHotelPickupSalesItemsOptions',
  getTierHotelCancelSalesItemsOptions: 'getTierHotelCancelSalesItemsOptions',
  getUserGroupCoupons: 'getUserGroupCoupons',
  getUserGroupInterActions: 'getUserGroupInterActions',
  getPaginatedSMSs: 'getPaginatedSMSs',
  getUserGroupAddresses: 'getUserGroupAddresses',
  getUserGroupAddressesValidForNewBooking: 'getUserGroupAddressesValidForNewBooking',
  postCar: 'postCar',
  getPaginatedBookings: 'getPaginatedBookings',
  getPaginatedRecallCandidates: 'getPaginatedRecallCandidates',
  getPaginatedBookingSMS: 'getPaginatedBookingSMS',
  getBookingSMS: 'getBookingSMS',
  postBookingSMS: 'postBookingSMS',
  postUserGroupSMS: 'postUserGroupSMS',
  postCreateRecall: 'postCreateRecall',
  postBookingSMSResend: 'postBookingSMSResend',
  postAddUserToUserGroup: 'postAddUserToUserGroup',
  getPaginatedMembershipDiscounts: 'getPaginatedMembershipDiscounts',
  getMembershipDiscount: 'getMembershipDiscount',
  postMembershipDiscount: 'postMembershipDiscount',
  patchMembershipDiscount: 'patchMembershipDiscount',
  deleteMembershipDiscount: 'deleteMembershipDiscount',
  getMembershipProgram: 'getMembershipProgram',
  getPaginatedMembershipPrograms: 'getPaginatedMembershipPrograms',
  postMembershipProgram: 'postMembershipProgram',
  patchMembershipProgram: 'patchMembershipProgram',
  deleteMembershipProgram: 'deleteMembershipProgram',
  postCreateBooking: 'postCreateBooking',
  patchConfirmAutoRecallBooking: 'patchConfirmAutoRecallBooking',
  getCar: 'getCar',
  getCarWheelSetTypes: 'getCarWheelSetTypes',
  getCarWheelPositions: 'getCarWheelPositions',
  getAvailableBookingTimeWindowsByDate: 'getAvailableBookingTimeWindowsByDate',
  getLanguages: 'getLanguages',
  postMakeGroupAdmin: 'postMakeGroupAdmin',
  postRemoveUserFromGroup: 'postRemoveUserFromGroup',
  patchUser: 'patchUser',
  getLatestDeliveryWindow: 'getLatestDeliveryWindow',
  getFirstAvailableDeliveryWindow: 'getFirstAvailableDeliveryWindow',
  postCapacityContribution: 'postCapacityContribution',
  patchCapacityContribution: 'patchCapacityContribution',
  getCapacityContribution: 'getCapacityContribution',
  patchBookingItem: 'patchBookingItem',
  getBookingStatuses: 'getBookingStatuses',
  getPaginatedUserGroupCarsForBooking: 'getPaginatedUserGroupCarsForBooking',
  downloadBookingReceipt: 'downloadBookingReceipt',
  getPaginatedInvoices: 'getPaginatedInvoices',
  getInvoice: 'getInvoice',
  deleteInvoice: 'deleteInvoice',
  postInvoiceToExcel: 'postInvoiceToExcel',
  postInvoice: 'postInvoice',
  patchInvoice: 'patchInvoice',
  getPaginatedCarMakes: 'getPaginatedCarMakes',
  getCarMake: 'getCarMake',
  postCarMake: 'postCarMake',
  patchCarMake: 'patchCarMake',
  getPaginatedCarModels: 'getPaginatedCarModels',
  getPaginatedCarModelsForMake: 'getPaginatedCarModelsForMake',
  getCarModel: 'getCarModel',
  postCarModel: 'postCarModel',
  patchCarModel: 'patchCarModel',
  getPaginatedCarGenerations: 'getPaginatedCarGenerations',
  getPaginatedCarGenerationsForModel: 'getPaginatedCarGenerationsForModel',
  getCarGeneration: 'getCarGeneration',
  postCarGeneration: 'postCarGeneration',
  patchCarGeneration: 'patchCarGeneration',
  getCarGenerationSearchNameApiProviders: 'getCarGenerationSearchNameApiProviders',
  getCarWheelSetSpecifications: 'getCarWheelSetSpecifications',
  postCarWheelSetSpecifications: 'postCarWheelSetSpecifications',
  patchCarWheelSetSpecifications: 'patchCarWheelSetSpecifications',
  patchBulkCarWheelSetSpecifications: 'patchBulkCarWheelSetSpecifications',
  getPaginatedCarWheelSetSpecifications: 'getPaginatedCarWheelSetSpecifications',
  getPaginatedCarWheelSetSpecificationsForGeneration: 'getPaginatedCarWheelSetSpecificationsForGeneration',
  getPaginatedSkills: 'getPaginatedSkills',
  getSkill: 'getSkill',
  postSkill: 'postSkill',
  patchSkill: 'patchSkill',
  deleteSkill: 'deleteSkill',
  getStorageUnits: 'getStorageUnits',
  getRouteLabels: 'getRouteLabels',
  getPaginatedPrintingLabels: 'getPaginatedPrintingLabels',
  getUserGroupBookings: 'getUserGroupBookings',
  getUserGroupNumberOfBookings: 'getUserGroupNumberOfBookings',
  getCarWheelSetsToPickUp: 'getCarWheelSetsToPickUp',
  getEditBookingPermissions: 'getEditBookingPermissions',
  getUpdateBookingTimeWindowData: 'getUpdateBookingTimeWindowData',
  postUserWithOrganization: 'postUserWithOrganization',
  getRoutesDragNDrop: 'getRoutesDragNDrop',
  postAddSalesItemsToBooking: 'postAddSalesItemsToBooking',
  getUnavailableSalesItems: 'getUnavailableSalesItems',
  postFetchAddons: 'postFetchAddons',
  getInitialAvailableSalesItems: 'getInitialAvailableSalesItems',
  getPotentialConflictsOnAddressChange: 'getPotentialConflictsOnAddressChange',
  getPotentialConflictsOnServicesChange: 'getPotentialConflictsOnServicesChange',
  getCarTireQuoteBySlug: 'getCarTireQuoteBySlug',
  getCarTireQuoteById: 'getCarTireQuoteById',
  getCarWheelSetsDimensions: 'getCarWheelSetsDimensions',
  postRejectTireOfferQuote: 'postRejectTireOfferQuote',
  getAddressSuggestions: 'getAddressSuggestions',
  postAddressCreatedFromPlaceId: 'postAddressCreatedFromPlaceId',
  getMe: 'getMe',
  getCurrencies: 'getCurrencies',
  getServiceWorkerContractAddresses: 'getServiceWorkerContractAddresses',
  getCarTireQuotes: 'getCarTireQuotes',
  getPaginatedCarTireQuotes: 'getPaginatedCarTireQuotes',
  getTireMeasurements: 'getTireMeasurements'
} as const;

export const URLKeys = {
  ...BaseUrlKeys,
  ...WorkerURLKeys,
  ...AdminURLKeys,
  ...CustomerURLKeys
};

type BaseUrlKeysType = keyof typeof BaseUrlKeys;

export type URLKeysTypes = BaseUrlKeysType | CustomerURLKeysType | AdminURLKeysType | WorkerURLKeysType;

export type InputData<urlKey extends URLKeysTypes> = ServerRequests[urlKey]['input'];
export type OutputData<urlKey extends URLKeysTypes> = ServerRequests[urlKey]['output'];

export type ReturnType<Type extends URLKeysTypes, _> = OutputData<Type>;

export type CommonAxiosResType<key extends URLKeysTypes> = AxiosResponse<
  OutputData<key>,
  {
    status: number;
    statusText: string;
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
    config: unknown;
    request?: unknown;
  }
>;

export const getUrls: {
  [key in URLKeysTypes]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>> | FormData;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
    skipBodyTransformation?: boolean;
  };
} = {
  getPaginatedCapabilities: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapabilities: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/`
      }),
    method: FetchMethods.get
  },
  postCapability: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/`
      }),
    method: FetchMethods.post,
    getBody: (data) => data
  },
  patchCapability: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/${id}/`,
        queryParams
      }),
    method: FetchMethods.patch,
    getBody: (data) => data
  },
  getCapability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/${id}/`
      }),
    method: FetchMethods.get
  },
  getCurrencies: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}currencies/`
      }),
    method: FetchMethods.get
  },
  postAddSalesItemsToBooking: {
    getUrl({ bookingId }) {
      return getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/add-sales-items/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getTimeZones: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}time-zones/`
      }),
    method: FetchMethods.get
  },
  getServiceOrganizations: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-organizations/`
      }),
    method: FetchMethods.get
  },
  getTireMeasurements: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}tire-measurements/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingCategoriesForNewBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-item-booking-categories/for-new-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRoutesDragNDrop: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/to-drag-and-drop/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteLabels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-label-printing/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedPrintingLabels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/for-label-printing/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteItemStatusById: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/status-details/`
      }),
    method: FetchMethods.get
  },
  getPaginatedSalesItemTags: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/tags/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postSalesItemTag: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/tags/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  patchSalesItemTag: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/tags/${id}/`
      }),
    method: FetchMethods.patch,
    getBody: (args) => args
  },
  postTokenLogin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}login-token/`
      }),
    getBody: (data) => data,
    getHeaders: ({ token }) => ({
      Authorization: `Token ${token}`
    }),
    method: FetchMethods.post
  },
  postVerifyPhoneNumber: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/verify-phone-number/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postUser: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  postDeliveryNotificationRequest: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}delivery-notification-requests/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  postUserWithOrganization: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/create-with-organization/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  patchUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${id}/`
      }),
    method: FetchMethods.patch,
    getBody: (args) => args
  },
  postEmailLogin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}login/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPhoneNumberVerification: {
    getUrl: ({ phoneNumber }) => {
      return getNoddiUrl({
        path: `${apiVersion}users/send-phone-number-verification/?phone_number=${phoneNumber}`
      });
    },
    method: FetchMethods.get
  },
  getPaginatedUsers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}users/`,
        queryParams
      }),
    method: FetchMethods.get,
    useSuperUserToken: true
  },
  getUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${id}/`
      }),
    method: FetchMethods.get
  },
  getMe: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user`
      }),
    method: FetchMethods.get
  },
  postAddUserToUserGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/add-user-to-group/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getGroupsOfUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/get-user-groups/${id}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCarGenerations: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarGenerationsForModel: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/car-generations/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getDataFromLicensePlateNumber: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/data-from-license-plate-number/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarFromLicensePlateNumber: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/from-license-plate-number/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getTierHotelPickupSalesItemsOptions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/wheels-pickup-options/`
      }),
    method: FetchMethods.get
  },
  getTierHotelCancelSalesItemsOptions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/tire-hotel-cancellation-options/`
      }),
    method: FetchMethods.get
  },
  getUserGroupCoupons: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/coupons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupInterActions: {
    getUrl: ({ userGroupId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/interactions/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedSMSs: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sms/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postCar: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPaginatedBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedRecallCandidates: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/recall-suggestions-list/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupBookings: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/bookings/`
      }),
    method: FetchMethods.get
  },
  getUserGroupNumberOfBookings: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/number-of-bookings/`
      }),
    method: FetchMethods.get
  },
  getPaginatedBookingSMS: {
    getUrl: ({ bookingId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/sms/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingSMS: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-sms/${id}/`
      }),
    method: FetchMethods.get
  },
  postBookingSMS: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postUserGroupSMS: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/sms-send/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postCreateRecall: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/recall-proposals-create/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postBookingSMSResend: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-sms/${id}/retry-failed-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getUserGroupAddresses: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/addresses?user_group_id=${userGroupId}`
      }),
    method: FetchMethods.get
  },
  getUserGroupAddressesValidForNewBooking: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/addresses-for-new-booking/`
      }),
    method: FetchMethods.get
  },
  getPaginatedMembershipDiscounts: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}/`
      }),
    method: FetchMethods.get
  },
  postMembershipDiscount: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postCreateBooking: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/`
      }),
    getBody: (data) => data,
    useSuperUserToken: true,
    method: FetchMethods.post
  },
  patchMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.delete
  },
  getMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedMembershipPrograms: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postMembershipProgram: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.delete
  },
  getAvailableBookingTimeWindowsByDate: {
    getUrl: (args) =>
      getNoddiUrl({
        path: `${apiVersion}delivery-windows/for-new-booking/`,
        queryParams: args
      }),
    method: FetchMethods.get
  },
  getCar: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-types/`
      }),
    method: FetchMethods.get
  },
  getCarWheelPositions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-positions/`
      }),
    method: FetchMethods.get
  },
  patchBookingItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/`
      }),
    getHeaders: () => ({
      'Content-Type': 'multipart/form-data'
    }),
    getBody: (args) => args.formData,
    skipBodyTransformation: true,
    method: FetchMethods.patch
  },
  postMakeGroupAdmin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/change-member-permission/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postRemoveUserFromGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/remove-user-from-group/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getLanguages: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}languages/`
      }),
    method: FetchMethods.get
  },
  getLatestDeliveryWindow: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}delivery-windows/latest-date/`
      }),
    method: FetchMethods.get,
    useSuperUserToken: true
  },
  getFirstAvailableDeliveryWindow: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}delivery-windows/earliest-date/`
      }),
    getBody: (data) => data,
    useSuperUserToken: true,
    method: FetchMethods.post
  },
  postCapacityContribution: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCapacityContribution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCapacityContribution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/${id}/`
      }),
    method: FetchMethods.get
  },
  getBookingStatuses: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/statuses/`
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupCarsForBooking: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/cars-for-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  downloadBookingReceipt: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/download-receipt/`
      }),
    responseType: 'arraybuffer',
    handleRes: async (res) => {
      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition.split('filename=')[1].split(';')[0];

      const bytes = new Uint8Array(res.data);
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      return res.data;
    },
    method: FetchMethods.get
  },
  getPaginatedInvoices: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/`
      }),
    method: FetchMethods.get
  },
  deleteInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/`
      }),
    method: FetchMethods.delete
  },
  postInvoiceToExcel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/to-excel/`
      }),
    method: FetchMethods.post,
    handleRes: async (res) => downloadToExcel(res),
    responseType: 'arraybuffer'
  },
  postInvoice: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}invoices/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCarMakes: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarMake: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarMake: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarMake: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCarModels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarModelsForMake: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/car-models/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarModel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarModel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-models/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarModel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCarGeneration: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarGeneration: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarGeneration: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCarGenerationSearchNameApiProviders: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-generation-search-names/api-providers/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetSpecifications: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarWheelSetSpecifications: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarWheelSetSpecifications: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  patchBulkCarWheelSetSpecifications: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/bulk-update/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCarWheelSetSpecifications: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarWheelSetSpecificationsForGeneration: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/car-wheel-set-specifications/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedSkills: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}skills/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}/`
      }),
    method: FetchMethods.get
  },
  postSkill: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}skills/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}/`
      }),
    method: FetchMethods.delete
  },
  getStorageUnits: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarWheelSetsToPickUp: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/to-pickup/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getEditBookingPermissions: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/edit-permissions/`
      }),
    method: FetchMethods.get
  },
  getUpdateBookingTimeWindowData: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/update-time-window-data/`
      }),
    method: FetchMethods.get
  },
  getUnavailableSalesItems: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/unavailable-for-new-booking/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postFetchAddons: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/addons-new/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPotentialConflictsOnAddressChange: {
    getUrl: ({ bookingId, addressId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/update-address-request/`,
        queryParams: { addressId }
      }),
    method: FetchMethods.get
  },
  getPotentialConflictsOnServicesChange: {
    getUrl: ({ bookingId, ...restParams }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/update-sales-items-request/`,
        queryParams: { ...restParams }
      }),
    method: FetchMethods.get
  },
  getInitialAvailableSalesItems: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/initial-available-for-booking/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getCarTireQuoteBySlug: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${slug}/`
      }),
    method: FetchMethods.get
  },
  patchConfirmAutoRecallBooking: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/recall-proposal-accept/`
      }),
    method: FetchMethods.patch
  },
  getCarTireQuoteById: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${id}/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetsDimensions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/dimensions/`
      }),
    method: FetchMethods.get
  },
  getAddressSuggestions: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}addresses/suggestions/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postRejectTireOfferQuote: {
    getUrl: ({ tireQuoteId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${tireQuoteId}/reject/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postAddressCreatedFromPlaceId: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}addresses/create-from-google-place-id/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getServiceWorkerContractAddresses: {
    getUrl: ({ serviceWorkerContractId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-contracts/${serviceWorkerContractId}/addresses/`
      }),
    method: FetchMethods.get
  },
  getCarTireQuotes: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/`,
        queryParams
      }),
    useSuperUserToken: true,
    method: FetchMethods.get
  },
  getPaginatedCarTireQuotes: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  ...getAdminUrls,
  ...getCustomerUrls,
  ...getWorkerUrls
} as const;

interface ServerRequests extends CustomerServerRequests, AdminServerRequests, WorkerServerRequests {
  getPaginatedCapabilities: {
    input: PaginatedInput;
    output: PaginatedResponse<CapabilityListItem[]>;
  };
  getCapabilities: {
    input: undefined;
    output: CapabilityListItem[];
  };
  getCapability: {
    input: { id: string };
    output: Capability;
  };
  postCapability: {
    input: CapabilityCreateInput;
    output: AxiosResponse<{ id: number }>;
  };
  patchCapability: {
    input: CapabilityUpdateInput;
    output: undefined;
  };
  postAddSalesItemsToBooking: {
    input: {
      bookingId: number;
      cars: {
        licensePlateNumber: string;
        countryCode: string;
        salesItemIds: number[];
      }[];
    };
    output: undefined;
  };
  getCurrencies: {
    input: undefined;
    output: string[];
  };
  getTimeZones: {
    input: undefined;
    output: string[];
  };
  getServiceOrganizations: {
    input: undefined;
    output: {
      name: string;
      id: number;
    }[];
  };
  getBookingCategoriesForNewBooking: {
    input: { addressId?: number };
    output: { brand: Brand; slug: string }[];
  };
  getRoutesDragNDrop: {
    input: { date: string; serviceDepartmentsIds: number[] };
    output: RearrangeRouteItem[];
  };
  getRouteItemStatusById: {
    input: {
      routeItemId: number;
    };
    output: RouteItem;
  };
  getPaginatedPrintingLabels: {
    input: PaginatedInput;
    output: PaginatedResponse<CarWheelSetForLabelPrint[]>;
  };
  getPaginatedSalesItemTags: {
    input: PaginatedInput;
    output: PaginatedResponse<SalesItemTag[]>;
  };
  postSalesItemTag: {
    input: { name: string };
    output: SalesItemTag;
  };
  patchSalesItemTag: {
    input: SalesItemTag;
    output: SalesItemTag;
  };
  postTokenLogin: {
    input: {
      token: string;
      userGroupsType: UserGroupsType;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postVerifyPhoneNumber: {
    input: {
      code: string;
      phoneNumber: string;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postUser: {
    input: PostUserInput;
    output: AxiosResponse<UserDataProps>;
  };
  postUserWithOrganization: {
    input: PostUserWithOrganizationInput;
    output: AxiosResponse<UserDataProps>;
  };
  postDeliveryNotificationRequest: {
    input: DeliveryNotificationRequestInput;
    output: unknown;
  };
  patchUser: {
    input: Omit<Partial<UserType>, 'id'> & { id: number; languageCode?: string };
    output: AxiosResponse<UserData>;
  };
  postEmailLogin: {
    input: {
      email: string;
      password: string;
    };
    output: AxiosResponse<UserDataProps>;
  };
  getPhoneNumberVerification: {
    input: {
      phoneNumber: string;
    };
    output: unknown;
  };
  getPaginatedUsers: {
    input: PaginatedInput;
    output: PaginatedResponse<UserListItem[]>;
  };
  getUser: {
    input: { id: number | string | undefined | null };
    output: UserType;
  };
  postAddUserToUserGroup: {
    input: { userId: number; groupId: number };
    output: { message: string };
  };
  getGroupsOfUser: {
    input: { id: number };
    output: UserGroupType[];
  };
  getPaginatedCarGenerations: {
    input: PaginatedInput & CarGenerationListInput;
    output: PaginatedResponse<CarGeneration[]>;
  };
  getPaginatedCarGenerationsForModel: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarGeneration[]>;
  };
  getDataFromLicensePlateNumber: {
    input: {
      licensePlateNumber: string;
      countryCode: string;
    };
    output: LicensePlateNumberData;
  };
  getCarFromLicensePlateNumber: {
    input: {
      number: string;
      countryCode: string;
    };
    output: CarDetailType;
  };
  patchBookingItem: {
    input: { formData: FormData; id: number };
    output: unknown;
  };
  getUnavailableSalesItems: {
    input: {
      addressId?: number;
      cars: SelectedSalesItemsCarGrouped[];
    };
    output: AxiosResponse<UnavailableSalesItem[]>;
  };
  postFetchAddons: {
    input: {
      addressId?: number;
      cars: SelectedSalesItemsCarGrouped[];
    };
    output: AxiosResponse<AvailableSalesItemsForBooking>;
  };
  getInitialAvailableSalesItems: {
    input: {
      addressId?: number;
      licensePlates: LicensePlateInfo[];
    };
    output: AxiosResponse<AvailableSalesItemsForBooking>;
  };
  getPotentialConflictsOnAddressChange: {
    input: {
      addressId?: number;
      bookingId: number;
    };
    output: {
      conflictingSalesItems: SalesItem[];
      isDeliveryWindowConflicting: boolean;
    };
  };
  getPotentialConflictsOnServicesChange: {
    input: {
      bookingId: number;
      selectedSalesItemIds: number[];
    };
    output: {
      isDeliveryWindowConflicting: boolean;
    };
  };
  getTierHotelPickupSalesItemsOptions: {
    input: undefined;
    output: TierHotelPickupSalesItemOption[];
  };
  getTierHotelCancelSalesItemsOptions: {
    input: undefined;
    output: SalesItemV2[];
  };
  getUserGroupCoupons: {
    input: {
      id: number;
      includeUsed?: boolean;
      includeUsedOnBookingIds: number[];
    };
    output: PublicCoupon[];
  };
  getUserGroupInterActions: {
    input: {
      userGroupId: number;
    };
    output: Interactions;
  };
  getPaginatedSMSs: {
    input: PaginatedInput & {
      receiverUserGroupIds?: number[];
    };
    output: PaginatedResponse<SMS[]>;
  };
  postCar: {
    input: CarPostInputType;
    output: CarDetailType;
  };
  getPaginatedBookings: {
    input: PaginatedInput & {
      userGroupId?: string;
      fromDate?: string | null;
      toDate?: string | null;
      userGroupIds?: string[];
      invoiceId?: number | null;
      statusIds?: number[];
      serviceDepartmentIds?: number[];
      serviceAreaIds?: number[];
      cities?: string[];
      zipCodes?: string[];
    };
    output: PaginatedResponse<BookingListItem[]>;
  };
  getPaginatedRecallCandidates: {
    input: PaginatedInput & {
      serviceDepartmentIds?: number[];
      serviceCategoryIds?: number[];
      cohorts?: string[];
      serviceAreaIds?: number[];
      excludeOrganizations?: boolean;
      organizationIds?: number[];
    };
    output: PaginatedResponse<AutoRecallSuggestion[]>;
  };
  getPaginatedBookingSMS: {
    input: PaginatedInput & { bookingId: number };
    output: BookingSMS[];
  };
  getBookingSMS: {
    input: {
      id: number;
    };
    output: BookingSMS;
  };
  postBookingSMS: {
    input: BookingSMSCreateInput;
    output: undefined;
  };
  postUserGroupSMS: {
    input: { body: string; userGroupId: number; subject: string };
    output: BookingSMS;
  };
  postCreateRecall: {
    input: {
      dateTo: string;
      dateFrom: string;
      createDepartmentContribution: boolean;
      recalls: {
        userGroupId: number;
        addressId: number;
        cars: {
          carId: number;
          selectedSalesItemIds: number[];
        }[];
      }[];
    };
    output: undefined;
  };
  postBookingSMSResend: {
    input: {
      id: number;
    };
    output: BookingSMS;
  };
  getUserGroupAddresses: {
    input: {
      userGroupId: number;
    };
    output: UserGroupAddress[];
  };
  getUserGroupAddressesValidForNewBooking: {
    input: {
      id: number;
    };
    output: BookingAddress[];
  };
  getPaginatedMembershipDiscounts: {
    input: PaginatedInput;
    output: PaginatedResponse<MembershipDiscountListItem[]>;
  };
  getMembershipDiscount: {
    input: { id: number };
    output: MembershipDiscount;
  };
  postMembershipDiscount: {
    input: MembershipDiscountCreateInput;
    output: MembershipDiscount;
  };
  postCreateBooking: {
    input: PostCreateBooking;
    output: AxiosResponse<{ id: number }>;
  };
  patchMembershipDiscount: {
    input: MembershipDiscountUpdateInput;
    output: MembershipDiscount;
  };
  deleteMembershipDiscount: {
    input: { id: number };
    output: undefined;
  };
  getMembershipProgram: {
    input: { id: number };
    output: MembershipProgram;
  };
  getPaginatedMembershipPrograms: {
    input: PaginatedInput;
    output: PaginatedResponse<MembershipProgramListItem[]>;
  };
  postMembershipProgram: {
    input: MembershipProgramCreateInput;
    output: MembershipProgram;
  };
  patchMembershipProgram: {
    input: MembershipProgramUpdateInput;
    output: MembershipProgram;
  };
  deleteMembershipProgram: {
    input: { id: number };
    output: undefined;
  };
  getCar: {
    input: { id: number };
    output: CarDetailType;
  };
  getCarWheelSetTypes: {
    input: undefined;
    output: CarWheelSetType[];
  };
  getCarWheelPositions: {
    input: undefined;
    output: CarWheelPosition[];
  };
  getAvailableBookingTimeWindowsByDate: {
    input: AvailableBookingTimeWindowsInput;
    output: AvailableBookingTimeWindowsByDateNew;
  };
  postMakeGroupAdmin: {
    input: GroupAdminType;
    output: { message: string };
  };
  postRemoveUserFromGroup: {
    input: GroupAdminType;
    output: { message: string };
  };
  getLanguages: {
    input: undefined;
    output: Language[];
  };
  getLatestDeliveryWindow: {
    input: undefined;
    output: { date: string | null };
  };
  getFirstAvailableDeliveryWindow: {
    input: { addressId: number; cars: SelectedSalesItemsCarGrouped[] };
    output: AxiosResponse<{ date: string | null }>;
  };
  postCapacityContribution: {
    input: CreateCapacityContributionDto;
    output: undefined;
  };
  patchCapacityContribution: {
    input: UpdateCapacityContributionDto;
    output: undefined;
  };
  getCapacityContribution: {
    input: { id: number };
    output: CapacityContributionDetails;
  };
  getBookingStatuses: {
    input: undefined;
    output: BookingStatusDto[];
  };
  getPaginatedUserGroupCarsForBooking: {
    input: PaginatedInput & { id: number | string | undefined | null };
    output: PaginatedResponse<CarListItem[]>;
  };
  downloadBookingReceipt: {
    input: { bookingId: number };
    output: BookingReceipt;
  };
  getPaginatedInvoices: {
    input: PaginatedInput & {
      userGroupId?: number;
      fromDate: Nullable<string>;
      toDate: Nullable<string>;
    };
    output: PaginatedResponse<InvoiceListItem[]>;
  };
  getInvoice: {
    input: { id: number };
    output: Invoice;
  };
  deleteInvoice: {
    input: { id: number };
    output: undefined;
  };
  postInvoiceToExcel: {
    input: { id: number };
    output: ArrayBufferLike;
  };
  postInvoice: {
    input: InvoiceCreateInput;
    output: Invoice;
  };
  patchInvoice: {
    input: InvoiceUpdateInput;
    output: Invoice;
  };
  getPaginatedCarMakes: {
    input: PaginatedInput;
    output: PaginatedResponse<CarMake[]>;
  };
  getCarMake: {
    input: { id: number };
    output: CarMake;
  };
  postCarMake: {
    input: CarMakeCreateInput;
    output: CarMake;
  };
  patchCarMake: {
    input: CarMakeUpdateInput;
    output: CarMake;
  };
  getPaginatedCarModels: {
    input: PaginatedInput;
    output: PaginatedResponse<CarModel[]>;
  };
  getPaginatedCarModelsForMake: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarModel[]>;
  };
  getCarModel: {
    input: { id: number };
    output: CarModel;
  };
  postCarModel: {
    input: CarModelCreateInput;
    output: CarModel;
  };
  patchCarModel: {
    input: CarModelUpdateInput;
    output: CarModel;
  };
  getCarGeneration: {
    input: { id: number };
    output: CarGeneration;
  };
  postCarGeneration: {
    input: CarGenerationCreateInput;
    output: CarGeneration;
  };
  patchCarGeneration: {
    input: CarGenerationUpdateInput;
    output: CarGeneration;
  };
  getCarGenerationSearchNameApiProviders: {
    input: undefined;
    output: OptionsTypeString[];
  };
  getCarWheelSetSpecifications: {
    input: { id: number };
    output: CarWheelSetSpecificationsNew;
  };
  postCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsCreateInput;
    output: CarWheelSetSpecifications;
  };
  patchCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsUpdateInput;
    output: CarWheelSetSpecifications;
  };
  patchBulkCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsPatchBulkUpdateInput;
    output: unknown;
  };
  getPaginatedCarWheelSetSpecifications: {
    input: PaginatedInput & CarWheelSetSpecificationsListInput;
    output: PaginatedResponse<CarWheelSetSpecifications[]>;
  };
  getPaginatedCarWheelSetSpecificationsForGeneration: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelSetSpecifications[]>;
  };
  getPaginatedSkills: {
    input: PaginatedInput;
    output: PaginatedResponse<SkillListItem[]>;
  };
  getSkill: {
    input: { id: number };
    output: Skill;
  };
  postSkill: {
    input: SkillPostInput;
    output: Skill;
  };
  patchSkill: {
    input: SkillPatchInput;
    output: Skill;
  };
  deleteSkill: {
    input: { id: number };
    output: unknown;
  };
  getStorageUnits: {
    input: StorageUnitInput;
    output: StorageUnit[];
  };
  getRouteLabels: {
    input: RouteLabelInput;
    output: RouteLabel[];
  };
  getCarWheelSetsToPickUp: {
    input: CarWheelSetToPickUpInput;
    output: CarWheelSetToPickUp[];
  };
  getEditBookingPermissions: {
    input: { id: string };
    output: EditBookingPermissions;
  };
  getUpdateBookingTimeWindowData: {
    input: { id: string };
    output: EditBookingTimeWindowData;
  };
  getUserGroupBookings: {
    input: { userGroupId?: number | null; statusIds: number[] };
    output: UserGroupBooking[];
  };
  getUserGroupNumberOfBookings: {
    input: { userGroupId: number };
    output: number;
  };
  getCarTireQuoteBySlug: {
    input: { slug: string };
    output: TireOfferV2;
  };
  patchConfirmAutoRecallBooking: {
    input: { bookingId: number };
    output: unknown;
  };
  getCarTireQuoteById: {
    input: { id: string };
    output: TireOfferV2;
  };
  getCarWheelSetsDimensions: {
    input: undefined;
    output: string[];
  };
  postRejectTireOfferQuote: {
    input: { tireQuoteId: number; comments: string };
    output: undefined;
  };
  getAddressSuggestions: {
    input: { queryInput: string; countryCodes: string[] };
    output: AddressSuggestion[];
  };
  postAddressCreatedFromPlaceId: {
    input: { placeId?: string };
    output: AxiosResponse<AddressForNewBooking>;
  };
  getMe: {
    input: undefined;
    output: UserData;
  };
  getServiceWorkerContractAddresses: {
    input: { serviceWorkerContractId: number };
    output: AddressOutput[];
  };
  getCarTireQuotes: {
    input: CarTireQuoteFilters;
    output: TireOfferMinimal[];
  };
  getPaginatedCarTireQuotes: {
    input: PaginatedInput & CarTireQuoteFilters;
    output: PaginatedResponse<TireOfferMinimal[]>;
  };
  getTireMeasurements: {
    input: TireMeasurementFilters;
    output: { value: string }[];
  };
}
