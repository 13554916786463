import QRCode, { QRCodeProps } from 'react-qr-code';

export const BaseQRCodeDisplay = ({
  value,
  size = 256,
  bgColor = 'white',
  fgColor = 'black',
  level = 'L'
}: QRCodeProps) => {
  return <QRCode value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} />;
};
