import { NoddiFeedbackBox } from '../molecules';

type InfoPageProps = {
  title: string;
  message: string;
};

const InfoPage = ({ message, title }: InfoPageProps) => {
  return (
    <div className='mx-auto flex min-h-[60vh] max-w-2xl items-center justify-center'>
      <NoddiFeedbackBox title={title} description={message} />
    </div>
  );
};

export { InfoPage };
