import { NoddiContainer, PickingList } from 'noddi-ui';

export const LoadingDockPreparation = () => {
  return (
    <NoddiContainer
      header='Loading dock preparation'
      description='An overview of car wheels that needs to be prepared by moving them to the loading dock for the
      upcoming routes'
    >
      <PickingList disablePersonalPickingList />
    </NoddiContainer>
  );
};
