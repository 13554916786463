import { noddiAsync, URLKeys } from 'noddi-async';
import { ApiErrorMessage, LoadingScreen, NoddiBasicCard, NoddiContainer } from 'noddi-ui';
import { NoddiIcon } from 'noddi-ui-common';
import { getMobileStoragePath } from 'noddi-util';
import { useParams } from 'react-router-dom';
import { BaseQRCodeDisplay } from '../../../../components/BaseQRCodeDisplay';

const environment = import.meta.env.MODE;

export default function CarWheelSetInInventory() {
  const { identifier } = useParams();
  const {
    data: storageObject,
    isPending,
    error: storageObjectError
  } = noddiAsync.useGet({
    type: URLKeys.getStorageObject,
    input: { storageObjectGroupIdentifier: identifier! }
  });

  if (isPending) {
    return <LoadingScreen />;
  }

  if (storageObjectError) {
    return (
      <NoddiContainer header='Print labels from storage object'>
        <ApiErrorMessage error={storageObjectError} />
      </NoddiContainer>
    );
  }

  const url = getMobileStoragePath({ identifier: storageObject.identifier, environment });

  return (
    <NoddiContainer header='Car wheel set in inventory'>
      <div className='flex flex-col gap-4'>
        <NoddiBasicCard className='flex flex-col gap-2'>
          {storageObject.carWheelSets.map((carWheelSet) => (
            <div key={carWheelSet.carWheelSetId}>
              <div className='flex items-center gap-12 py-4'>
                <BaseQRCodeDisplay size={160} value={url} />
                <div className='flex w-5/12 flex-col gap-2'>
                  <h3 className='font-bold'>{carWheelSet.carLicensePlate.number}</h3>
                  <div className='flex flex-col gap-1'>
                    <p className='font-bold'>{`${carWheelSet.carMake} - ${carWheelSet.carModel}`}</p>
                    <p>{carWheelSet.carWheelSetType.label} tire</p>
                    <p>{carWheelSet.carOwner.name}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </NoddiBasicCard>
        <NoddiBasicCard className='flex flex-col gap-2'>
          <p className='text-6'>Car wheel sets</p>
          <div className='flex flex-col gap-2'>
            {storageObject.carWheelSets.map((carWheelSet) => (
              <div key={carWheelSet.carWheelSetId} className='flex flex-col gap-2'>
                <div className='flex items-center gap-2'>
                  <NoddiIcon name='UserCircle' size='medium' />
                  <p>{carWheelSet.carOwner.name}</p>
                </div>
                <div className='flex items-center gap-2'>
                  <NoddiIcon name='Car' size='medium' />
                  <h3>
                    {carWheelSet.carLicensePlate.number} {`${carWheelSet.carMake} - ${carWheelSet.carModel}`}
                  </h3>
                </div>
                <div className='flex items-center gap-2'>
                  <NoddiIcon name='WheelAngle' size='medium' />
                  <p>{carWheelSet.carWheelSetType.label} tire</p>
                </div>
              </div>
            ))}
          </div>
        </NoddiBasicCard>
      </div>
    </NoddiContainer>
  );
}
