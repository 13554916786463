import { noddiAsync, URLKeys } from 'noddi-async';
import { CarListItem } from 'noddi-async/src/types';
import { getCarDisplayName, NoddiBasicCard, NoddiContainer, NoddiTable, usePaginationQueryParam } from 'noddi-ui';
import { NoddiButton, NoddiIcon } from 'noddi-ui-common';
import { useState } from 'react';
import { CarWheelSetSelection } from './CarWheelSetSelection';
import { StepLabel } from './StepLabel';

const getTableHeaders = () => ['License plate', 'User name', 'Tire type'];

export const CreateStorageObject = () => {
  const { page, setPage, pageSize, setPageSize, search, setSearch } = usePaginationQueryParam();
  const [car, setCar] = useState<CarListItem | null>(null);
  const [seasonType, setSeasonType] = useState<string>('');
  const [isEditingCar, setIsEditingCar] = useState(false);

  const { data: carWheelSets, isPending: isCarWheelSetsPending } = noddiAsync.useGet({
    type: URLKeys.getPaginatedPrintingLabels,
    input: { page, pageSize, search }
  });

  const tableItems = carWheelSets?.results.map((item) => {
    return {
      renderItems: [
        `${item.car.carGeneration?.make?.name} ${item.car.carGeneration?.model?.name} - ${item.car.licensePlateNumber}`,
        item.userGroupName,
        item.type.label
      ],
      item
    };
  });

  return (
    <NoddiContainer header='Print labels' description='Print labels for car wheel sets'>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-2'>
          <div className='mb-2 flex items-center gap-2 text-5'>
            <StepLabel stepLabel='1' />
            <p>Select wheel set</p>
          </div>
          {car?.id && (
            <NoddiBasicCard className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <NoddiIcon name='Car' />
                <p className='font-semibold'>{`${getCarDisplayName(car)} - ${seasonType}`}</p>
              </div>
              <NoddiButton variant='link' size='small' onPress={() => setIsEditingCar((prev) => !prev)}>
                {isEditingCar ? 'Cancel' : 'Edit'}
              </NoddiButton>
            </NoddiBasicCard>
          )}
          {(isEditingCar || !car) && (
            <NoddiTable
              page={page}
              items={tableItems}
              setPage={setPage}
              pageSize={pageSize}
              onRowClick={(item) => {
                setCar(item.car);
                setSeasonType(item.type.label);
                setIsEditingCar(false);
              }}
              setPageSize={setPageSize}
              count={carWheelSets?.count}
              headers={getTableHeaders()}
              isLoading={isCarWheelSetsPending}
              searchProps={{
                defaultValue: search,
                onSearch: (e) => {
                  setPage(1);
                  setSearch(e.target.value);
                },
                placeholder: 'Search by license plate'
              }}
            />
          )}
        </div>
        {car && seasonType && <CarWheelSetSelection carId={car.id} seasonLabel={seasonType} />}
      </div>
    </NoddiContainer>
  );
};
