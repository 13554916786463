import { noddiAsync, URLKeys } from 'noddi-async';
import { StorageObjectV2 } from 'noddi-async/src/types';
import { ApiErrorMessage, NoddiCircularLoader, NoddiFeedbackBox, useNoddiToast } from 'noddi-ui';
import { NoddiButton, NoddiSelectableBox } from 'noddi-ui-common';
import { getMobileStoragePath } from 'noddi-util';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { BaseQRCodeDisplay } from '../../components/BaseQRCodeDisplay';
import { StepLabel } from './StepLabel';

interface CarWheelSetSelectionProps {
  carId: number;
  seasonLabel: string;
}

const environment = import.meta.env.MODE;

export const CarWheelSetSelection = ({ carId, seasonLabel }: CarWheelSetSelectionProps) => {
  const [storageObject, setStorageObject] = useState<StorageObjectV2>();
  const { noddiToast } = useNoddiToast();
  const {
    data: car,
    isPending: isCarPending,
    error: carError
  } = noddiAsync.useGet({
    type: URLKeys.getCar,
    input: { id: carId },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!carId
    }
  });
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setStorageObject(undefined);
    }
  });

  useEffect(() => {
    if (componentRef.current && storageObject) {
      //only print when storage object exists.
      handlePrint();
    }
  }, [handlePrint, storageObject]);

  const { mutateAsync: createStorageObject, isPending: isCreateStorageObjectPending } = noddiAsync.usePost({
    type: URLKeys.postCreateStorageObjectForWheels,
    queryConfig: {
      onError: () => {
        noddiToast.error('Failed to create storage object');
      }
    }
  });

  const [selectedCarWheelIds, setSelectedCarWheelIds] = useState<number[]>([]);
  const wheelSet = car?.wheelSets?.find((item) => item.type.label === seasonLabel);

  useEffect(() => {
    if (wheelSet) {
      setSelectedCarWheelIds(wheelSet.wheels.map((wheel) => wheel.id));
    }
  }, [wheelSet]);

  if (isCarPending) {
    return <NoddiCircularLoader />;
  }
  if (carError) {
    return <ApiErrorMessage error={carError} />;
  }

  if (!wheelSet) {
    return <NoddiFeedbackBox description='No wheel set found - contact support ' />;
  }

  return (
    <>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center gap-2 text-5'>
          <StepLabel stepLabel='2' />
          <p>Select wheels to store</p>
        </div>
        <div className='flex flex-col gap-4'>
          {wheelSet.wheels.map((wheel) => {
            const isSelected = selectedCarWheelIds.some((selectedWheelId) => selectedWheelId === wheel.id);
            return (
              <NoddiSelectableBox
                key={wheel.id}
                isSelected={isSelected}
                setIsSelected={() =>
                  setSelectedCarWheelIds((prevSelectedWheels) => {
                    if (isSelected) {
                      return prevSelectedWheels.filter((selectedWheelId) => selectedWheelId !== wheel.id);
                    }
                    return [...prevSelectedWheels, wheel.id];
                  })
                }
              >
                <p>{wheel.position.label}</p>
              </NoddiSelectableBox>
            );
          })}
        </div>
        <NoddiButton
          onPress={async () => {
            const res = await createStorageObject({ carWheelIds: selectedCarWheelIds });
            setStorageObject(res.data);
          }}
          disabled={selectedCarWheelIds.length === 0}
          loading={isCreateStorageObjectPending}
        >
          Print labels
        </NoddiButton>
      </div>
      <div className='hidden'>
        <div ref={componentRef}>
          {storageObject?.carWheelSets?.map((carWheelSet) => (
            <div key={carWheelSet.carWheelSetId}>
              {carWheelSet.carWheelStorageObjects.map((carWheelStorageObject) => (
                <div
                  key={carWheelStorageObject.identifier}
                  className='flex h-[235px] items-center justify-center gap-6'
                >
                  <BaseQRCodeDisplay
                    size={160}
                    value={getMobileStoragePath({ identifier: storageObject.identifier, environment })}
                  />
                  <div className='flex w-5/12 flex-col gap-2'>
                    <h3 className='font-bold'>{carWheelSet.carLicensePlate.number}</h3>
                    <div className='flex flex-col gap-1'>
                      <p className='font-bold'>{`${carWheelSet.carMake} - ${carWheelSet.carModel}`}</p>
                      <p>{carWheelSet.carWheelSetType.label} tire</p>
                      <p>{carWheelSet.carOwner.name}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
