import { AxiosResponse } from 'axios';
import {
  AvailableSalesItemForBookingItem,
  AvailableSalesItemForNewCarInBooking,
  BookingItemOnRoute,
  BookingItemServiceTasksStatus,
  CarTireMeasurement,
  CarTireMeasurements,
  EstimatedRoutesPayment,
  PaginatedInput,
  PaginatedResponse,
  RegisteredCapacityContribution,
  RouteForServiceWorker,
  RouteItemCustomerOverview,
  RouteItemForServiceWorker,
  RouteSettlementPayment,
  ServiceWorkerFeedback,
  StorageArea,
  UnableToCompleteReason,
  WorkItem
} from '../types';
import { StorageEventType, StorageObjectV2 } from '../types/shared/storageObject';
import { CarWheelInspectionTireDimensionPayload } from '../types/workerapp/inspection';
import { apiVersion, FetchMethods, ResponseType } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const WorkerURLKeys = {
  postDelayedMessage: 'postDelayedMessage',
  postMarkRouteItemComplete: 'postMarkRouteItemComplete',
  getUnableToCompleteReasons: 'getUnableToCompleteReasons',
  getCapacityContributionsForServiceWorker: 'getCapacityContributionsForServiceWorker',
  postAddExtraSalesItemsToBookingItem: 'postAddExtraSalesItemsToBookingItem',
  postMarkRouteItemStarted: 'postMarkRouteItemStarted',
  getRouteSettlementsForWorker: 'getRouteSettlementsForWorker',
  postStartRouteForServiceWorker: 'postStartRouteForServiceWorker',
  postConfirmRouteForServiceWorker: 'postConfirmRouteForServiceWorker',
  getRoutesForServiceWorker: 'getRoutesForServiceWorker',
  getRouteItemsForServiceWorker: 'getRouteItemsForServiceWorker',
  getEstimatedRoutePayment: 'getEstimatedRoutePayment',
  getDelayForRoute: 'getDelayForRoute',
  getBookingItemsForRoute: 'getBookingItemsForRoute',
  getBookingItemForRoute: 'getBookingItemForRoute',
  getRouteItemCustomerOverview: 'getRouteItemCustomerOverview',
  postMarkBookingItemsAsComplete: 'postMarkBookingItemsAsComplete',
  postMarkBookingAsComplete: 'postMarkBookingAsComplete',
  postCarTireMeasurementsAttachedToBooking: 'postCarTireMeasurementsAttachedToBooking',
  postNextCustomerArrivalSMS: 'postNextCustomerArrivalSMS',
  getAvailableSalesItemsForBookingItem: 'getAvailableSalesItemsForBookingItem',
  postTranslateText: 'postTranslateText',
  postUnableToCompleteCarOnBookingItem: 'postUnableToCompleteCarOnBookingItem',
  getSalesItemForNewCarInBooking: 'getSalesItemForNewCarInBooking',
  getCapacityContributionsForDate: 'getCapacityContributionsForDate',
  getCapacityContributionDates: 'getCapacityContributionDates',
  getServiceWorkerFeedbacks: 'getServiceWorkerFeedbacks',
  postUnableToMeasureTires: 'postUnableToMeasureTires',
  getStorageAreas: 'getStorageAreas',
  patchMarkBookingItemSalesItemAsComplete: 'patchMarkBookingItemSalesItemAsComplete',
  postCarTireMeasurements: 'postCarTireMeasurements',
  getBookingItemServiceTasksStatus: 'getBookingItemServiceTasksStatus',
  postCarInspectionImage: 'postCarInspectionImage',
  postCarInspectionDimensions: 'postCarInspectionDimensions',
  postCarWheelInspectionManual: 'postCarWheelInspectionManual',
  postCreateStorageObjectForWheels: 'postCreateStorageObjectForWheels',
  getStorageObject: 'getStorageObject',
  patchStorageObject: 'patchStorageObject'
} as const;

export type WorkerURLKeysType = keyof typeof WorkerURLKeys;

type InputDataOrVoid<Type extends WorkerURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends WorkerURLKeysType> = WorkerServerRequests[urlKey]['input'];

export const getWorkerUrls: {
  [key in WorkerURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  postDelayedMessage: {
    getUrl({ routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/send-delay-sms/`
      });
    },
    getBody: ({ numMinutes }) => ({ numMinutes }),
    method: FetchMethods.post
  },
  postMarkRouteItemComplete: {
    getUrl({ bookingId }) {
      return getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/mark-as-complete/`
      });
    },
    getBody: ({ commentsWorker, notifyCustomer }) => ({ commentsWorker, notifyCustomer }),
    method: FetchMethods.post
  },
  getCapacityContributionsForServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  getUnableToCompleteReasons: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/unable-to-complete-reasons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postAddExtraSalesItemsToBookingItem: {
    getUrl({ id }) {
      return getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/add-extra-sales-items/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMarkRouteItemStarted: {
    getUrl({ routeItemId }) {
      return getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/mark-as-started/`
      });
    },
    method: FetchMethods.post
  },
  getRouteSettlementsForWorker: {
    getUrl: ({ userId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}route-settlements/for-worker/${userId}/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postStartRouteForServiceWorker: {
    getUrl({ routeId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/start-route-for-service-worker/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postConfirmRouteForServiceWorker: {
    getUrl({ routeId }) {
      return getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/confirm-route-for-service-worker/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getRoutesForServiceWorker: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-service-worker/`
      }),
    method: FetchMethods.get
  },
  getDelayForRoute: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/delay/`
      }),
    method: FetchMethods.get
  },
  getEstimatedRoutePayment: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/estimated-payment/`
      }),
    method: FetchMethods.get
  },
  getBookingItemsForRoute: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/booking-items/`
      }),
    method: FetchMethods.get
  },
  getBookingItemForRoute: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/for-route-item/`
      }),
    method: FetchMethods.get
  },
  getRouteItemsForServiceWorker: {
    getUrl: ({ routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items-for-service-worker/`
      }),
    method: FetchMethods.get
  },
  getRouteItemCustomerOverview: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/customer-overview/`
      }),
    method: FetchMethods.get
  },
  postMarkBookingItemsAsComplete: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/mark-as-complete/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMarkBookingAsComplete: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/mark-as-complete/`
      }),
    method: FetchMethods.post
  },
  postCarTireMeasurementsAttachedToBooking: {
    getUrl: ({ carId }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${carId}/add-measurements/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postCarTireMeasurements: {
    getUrl: ({ carId }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${carId}/tire-measurements-add/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postNextCustomerArrivalSMS: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/send-next-arrival-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getAvailableSalesItemsForBookingItem: {
    getUrl: ({ bookingItemId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/available-sales-items/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postTranslateText: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}core/translate-text/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postUnableToCompleteCarOnBookingItem: {
    getUrl: ({ routeItemId, routeId }) =>
      getNoddiUrl({
        path: `${apiVersion}routes/${routeId}/route-items/${routeItemId}/mark-booking-item-as-unable-to-complete/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getSalesItemForNewCarInBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/available-for-new-car-on-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapacityContributionsForDate: {
    getUrl: ({ serviceWorkerId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${serviceWorkerId}/capacity-contributions-v2/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },
  getCapacityContributionDates: {
    getUrl: ({ serviceWorkerId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${serviceWorkerId}/capacity-contribution-dates/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },
  getServiceWorkerFeedbacks: {
    getUrl: ({ serviceWorkerId, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}service-workers/${serviceWorkerId}/feedbacks/`,
        queryParams: rest
      }),
    method: FetchMethods.get
  },
  postUnableToMeasureTires: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/skip-car-wheel-measurements/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchMarkBookingItemSalesItemAsComplete: {
    getUrl: ({ bookingItemId, salesItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/sales_items/${salesItemId}/mark-as-complete/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getStorageAreas: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-areas/`
      }),
    method: FetchMethods.get
  },
  getBookingItemServiceTasksStatus: {
    getUrl: ({ bookingItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${bookingItemId}/tasks-status/`
      }),
    method: FetchMethods.get
  },
  postCarInspectionImage: {
    getUrl: ({ imageData, ...rest }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-inspections/`,
        queryParams: rest
      }),
    //formdata has a bit different structure than a regular object
    // so no way to type up this nicely
    //@ts-ignore
    getBody: ({ imageData }) => {
      const formData = new FormData();
      //@ts-ignore
      formData?.append('image', imageData);

      return formData;
    },
    getHeaders: () => ({
      'Content-Type': 'multipart/form-data'
    }),
    skipBodyTransformation: true,
    method: FetchMethods.post
  },
  postCarInspectionDimensions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-inspections/`
      }),

    getBody: (data) => data,
    method: FetchMethods.post
  },
  postCarWheelInspectionManual: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-inspections/bulk-update/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  postCreateStorageObjectForWheels: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}storage-object-groups/create-for-wheels/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getStorageObject: {
    getUrl: ({ storageObjectGroupIdentifier }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-object-groups/${storageObjectGroupIdentifier}/`
      }),
    method: FetchMethods.get
  },
  patchStorageObject: {
    getUrl: ({ storageObjectGroupIdentifier }) =>
      getNoddiUrl({
        path: `${apiVersion}storage-object-groups/${storageObjectGroupIdentifier}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  }
} as const;

export interface WorkerServerRequests {
  postDelayedMessage: {
    input: {
      routeItemId: number;
      numMinutes: number | undefined;
    };
    output: undefined;
  };
  postMarkRouteItemComplete: {
    input: {
      bookingId: number;
      commentsWorker?: string;
      notifyCustomer?: boolean;
    };
    output: undefined;
  };
  getCapacityContributionsForServiceWorker: {
    input: undefined;
    output: WorkItem[];
  };
  getUnableToCompleteReasons: {
    input: {
      isForBooking?: boolean;
      salesItemIds?: number[];
    };
    output: UnableToCompleteReason[];
  };
  postAddExtraSalesItemsToBookingItem: {
    input: {
      id: number;
      salesItemIds: number[];
    };
    output: undefined;
  };
  postMarkRouteItemStarted: {
    input: {
      routeItemId: number;
    };
    output: undefined;
  };
  getRouteSettlementsForWorker: {
    input: PaginatedInput & { userId: string; type: number };
    output: PaginatedResponse<RouteSettlementPayment[]>;
  };
  postStartRouteForServiceWorker: {
    input: {
      routeId: number;
    };
    output: undefined;
  };
  postConfirmRouteForServiceWorker: {
    input: {
      routeId: number;
    };
    output: undefined;
  };
  getRoutesForServiceWorker: {
    input: undefined;
    output: RouteForServiceWorker[];
  };
  getRouteItemsForServiceWorker: {
    input: { routeId: number };
    output: RouteItemForServiceWorker[];
  };
  getEstimatedRoutePayment: {
    input: { routeId: number };
    output: EstimatedRoutesPayment;
  };
  getDelayForRoute: {
    input: { routeId: number };
    output: { delayInSeconds: number };
  };
  getBookingItemsForRoute: {
    input: { routeItemId: number };
    output: BookingItemOnRoute[];
  };
  getBookingItemForRoute: {
    input: { bookingItemId: number };
    output: BookingItemOnRoute;
  };
  getRouteItemCustomerOverview: {
    input: { routeItemId: number };
    output: RouteItemCustomerOverview;
  };
  postMarkBookingItemsAsComplete: {
    input: { bookingItemId: number; skipCarWheelMeasurementsReason?: string | null };
    output: undefined;
  };
  postMarkBookingAsComplete: {
    input: { bookingId: number };
    output: undefined;
  };
  postCarTireMeasurementsAttachedToBooking: {
    input: { carId: number; bookingItemId?: number; measurements: CarTireMeasurements };
    output: undefined;
  };
  postCarTireMeasurements: {
    input: { carId: number; tireMeasurements: CarTireMeasurement[] };
    output: undefined;
  };
  postNextCustomerArrivalSMS: {
    input: { routeItemId: number; numMinutes: number };
    output: undefined;
  };
  getAvailableSalesItemsForBookingItem: {
    input: { bookingItemId: number; type?: 'extra' | null };
    output: AvailableSalesItemForBookingItem[];
  };
  postTranslateText: {
    input: { text: string; targetLanguage: string; sourceLanguage?: string };
    output: { data: { translatedText: string } };
  };
  postUnableToCompleteCarOnBookingItem: {
    input: {
      routeItemId: number;
      routeId: number;
      bookingItemId: number;
      commentsInternal: string;
      commentsPublic: string;
      salesItems: { salesItemId: number; unableToCompleteReasonId: number }[];
    };
    output: { data: { translatedText: string } };
  };
  getSalesItemForNewCarInBooking: {
    input: { bookingId: number; carLicensePlateNumber: string; carCountryCode: string };
    output: AvailableSalesItemForNewCarInBooking[];
  };
  getCapacityContributionsForDate: {
    input: { serviceWorkerId: number; fromDate: string; toDate: string };
    output: RegisteredCapacityContribution[];
  };
  getCapacityContributionDates: {
    input: { serviceWorkerId: number; fromDate: string };
    output: { date: string; id: number }[];
  };
  getServiceWorkerFeedbacks: {
    input: {
      serviceWorkerId: number;
      bookingFromDate?: string;
      bookingToDate?: string;
      createdFromDate?: string;
      createdToDate?: string;
    };
    output: ServiceWorkerFeedback;
  };
  postUnableToMeasureTires: {
    input: { bookingItemId: number; skipReason: string };
    output: undefined;
  };
  patchMarkBookingItemSalesItemAsComplete: {
    input: { bookingItemId: number; salesItemId: number; completedAt: boolean };
    output: BookingItemOnRoute;
  };
  getStorageAreas: {
    input: undefined;
    output: StorageArea[];
  };
  getBookingItemServiceTasksStatus: {
    input: { bookingItemId: number };
    output: BookingItemServiceTasksStatus;
  };
  postCarInspectionImage: {
    input: {
      tireSeasonType: string;
      carWheelPosition: string;
      imageData: {
        uri: string;
        name: string;
        type: string;
      };
      inspectionContext: string;
      carId: number;
      bookingItemId?: number;
    };
    output: { id: number; isImageTire: boolean; tireSpecification: string };
  };
  postCarInspectionDimensions: {
    input: CarWheelInspectionTireDimensionPayload;
    output: { id: number; isImageTire: boolean; tireSpecification: string };
  };
  postCarWheelInspectionManual: {
    input: CarWheelInspectionTireDimensionPayload;
    output: undefined;
  };
  postCreateStorageObjectForWheels: {
    input: { carWheelIds: number[] };
    output: AxiosResponse<StorageObjectV2>;
  };
  getStorageObject: {
    input: {
      storageObjectGroupIdentifier: string;
    };
    output: StorageObjectV2;
  };
  patchStorageObject: {
    input: {
      storageObjectGroupIdentifier: string;
      eventType?: StorageEventType;
      storageUnitId?: number;
      notes?: string;
    };
    output: AxiosResponse<StorageObjectV2>;
  };
}
