import { SxProps } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { NoddiButton } from 'noddi-ui-common';
import { useNavigate } from 'react-router-dom';
import { useIsMobile } from '../../../hooks';
export interface LinkProps {
  title: string;
  path?: string;
}

export interface BreadcrumbProps {
  links: LinkProps[];
  sx?: SxProps;
}

export function NoddiBreadcrumbs({ links, sx }: BreadcrumbProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  return (
    <div className='mr-14'>
      <Breadcrumbs maxItems={isMobile ? 3 : 4} separator='/' sx={sx}>
        {links.map(({ title, path }) =>
          path ? (
            <NoddiButton className='px-0' variant='link' onPress={() => navigate(path)} key={path}>
              {title}
            </NoddiButton>
          ) : (
            // TODO : This should be 4 after font size is fixed in noddi-ui common for button
            <span className='text-3.5' key={title}>
              {title}
            </span>
          )
        )}
      </Breadcrumbs>
    </div>
  );
}
