import { useAuthContext } from 'noddi-provider';
import { commonTexts, NoddiCheckbox, NoddiDatePicker } from 'noddi-ui';
import { useEffect, useState } from 'react';

import { useNoddiToast } from '../../../providers/toast';
import { PickingListView } from './PickingList';

type PickingListProps = {
  disablePersonalPickingList?: boolean;
  translations?: {
    selectDate: string;
    myRouteOnly: string;
    youDontHaveWorkerId: string;
    missingStorageArea: string;
    missingStorageLocation: string;
    noWheelSetsFound: string;
    tryChoosingAnotherDateOrStorageFacility: string;
    multipleRoutesFound: string;
    atLoadingDock: string;
    inTransit: string;
    refresh: string;
  };
};

export const PickingList = ({ disablePersonalPickingList = false, translations }: PickingListProps) => {
  const [date, setDate] = useState(new Date());
  const { userData } = useAuthContext();
  const selectedWorkerId = userData?.user?.serviceWorker?.id;
  const [showPersonalList, setShowPersonalList] = useState(false);

  const texts = translations ?? {
    selectDate: commonTexts.selectDate,
    myRouteOnly: commonTexts.myRouteOnly,
    youDontHaveWorkerId: commonTexts.youDontHaveWorkerId,
    ...commonTexts.pickingListTranslations
  };
  useEffect(() => {
    if (selectedWorkerId && !disablePersonalPickingList) {
      setShowPersonalList(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkerId]);

  const { noddiToast } = useNoddiToast();

  return (
    <>
      <div className='flex gap-4 rounded-xl bg-primary-white p-4'>
        <div className='flex items-end gap-4'>
          <div className='flex w-[10rem]'>
            <NoddiDatePicker label={texts.selectDate} value={date} onChange={(date) => setDate(date!)} />
          </div>
        </div>
        {!disablePersonalPickingList && (
          <div className='flex items-center gap-2'>
            <label htmlFor={texts.myRouteOnly}>{texts.myRouteOnly}</label>
            <NoddiCheckbox
              name={texts.myRouteOnly}
              checked={showPersonalList}
              onChange={(event) => {
                if (selectedWorkerId) {
                  setShowPersonalList(event.target.checked);
                } else {
                  noddiToast.error(texts.youDontHaveWorkerId);
                }
              }}
            />{' '}
          </div>
        )}
      </div>
      <PickingListView selectedWorkerId={selectedWorkerId} showPersonalList={showPersonalList} date={date} />
    </>
  );
};
