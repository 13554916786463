import { UserRoleType } from 'noddi-provider';
import { useState } from 'react';
import { NoddiBasicCard } from '../../../atoms';
import { useNavigateOnLoginSuccess } from '../../../hooks';
import { NoddiFeedbackBox } from '../../../molecules';
import { NoddiLogo } from '../../Elements';
import { ContentWrapper } from '../../Elements/Layouts/ContentWrapper';
import { EmailLogin } from './EmailLogin';

type LoginProps = {
  requiredLoginLevel: UserRoleType;
};

export const LoginScreen = ({ requiredLoginLevel }: LoginProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { navigateOnLoginSuccess } = useNavigateOnLoginSuccess();

  return (
    <ContentWrapper className='min-h-[80vh] max-w-sm justify-center'>
      <NoddiBasicCard className='flex flex-col gap-6'>
        <div className='flex items-center justify-between gap-4'>
          <h3 className='font-bold text-5'>Login</h3>
          <NoddiLogo width={67} height={19} />
        </div>

        <EmailLogin
          setErrorMessage={setErrorMessage}
          onLoginSuccess={() => navigateOnLoginSuccess({ loginSuccessPath: '/' })}
          requiredLoginLevels={[requiredLoginLevel]}
        />

        {errorMessage && <NoddiFeedbackBox variant='error' description={errorMessage} />}
      </NoddiBasicCard>
    </ContentWrapper>
  );
};
