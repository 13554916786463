import { NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';

const paginationQueryParamKeys = {
  page: 'page',
  pageSize: 'pageSize',
  search: 'search'
} as const;

export function usePaginationQueryParam() {
  const [page, setPage] = useQueryParam(paginationQueryParamKeys.page, withDefault(NumberParam, 1));
  const [pageSize, setPageSize] = useQueryParam(paginationQueryParamKeys.pageSize, withDefault(NumberParam, 10));
  const [search, setSearch] = useQueryParam(paginationQueryParamKeys.search, withDefault(StringParam, ''));

  return { page, setPage, pageSize, setPageSize, search, setSearch, paginationQueryParamKeys };
}
