import { noddiAsync, URLKeys } from 'noddi-async';
import { StorageAreaOverview, StorageUnitOverviewType } from 'noddi-async/src/types';
import { ErrorPage, LoadingScreen, NoddiBasicCard } from 'noddi-ui';

import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import { useStorageFacility } from '../../StorageFacilityStore';
import { StorageAreaCreateDrawer } from './StorageAreaCreateDrawer';
import { StorageAreaDrawer } from './StorageAreaDrawer';
import { StorageUnitDrawer } from './StorageUnitDrawer';

const defaultNumberToShow = 8;
const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

export const StorageAreaList = () => {
  const [storageAreaToEdit, setStorageAreaToEdit] = useState<StorageAreaOverview | null>(null);
  const [isCreateStorageAreaOpen, setIsCreateStorageAreaOpen] = useState(false);
  const [storageUnit, setStorageUnit] = useState<StorageUnitOverviewType | null>(null);
  const [expandedStorageAreas, setExpandedStorageAreas] = useState<Record<number, boolean>>({});

  const { storageFacilityId } = useStorageFacility();

  const {
    data: storageAreas,
    isPending: isStorageAreasPending,
    error: storageAreasError
  } = noddiAsync.useGet({
    type: URLKeys.getStorageAreasOverview,
    input: { storageFacilityIds: [storageFacilityId] }
  });

  if (storageAreasError) {
    return <ErrorPage apiError={storageAreasError} />;
  }
  if (isStorageAreasPending) {
    return <LoadingScreen />;
  }

  const toggleExpandStorageArea = (storageAreaId: number) => {
    setExpandedStorageAreas((prev) => ({
      ...prev,
      [storageAreaId]: !prev[storageAreaId]
    }));
  };

  const expandAllStorageAreas = () => {
    setExpandedStorageAreas(storageAreas.reduce((acc, area) => ({ ...acc, [area.id]: true }), {}));
  };

  const closeAllStorageAreas = () => {
    setExpandedStorageAreas({});
  };

  return (
    <>
      <div className='mt-5 flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-end'>
        <NoddiBasicCard className='flex w-full flex-col gap-4 lg:w-fit'>
          <div className='flex items-center gap-2'>
            <div className='ml-2 size-3 rounded-full bg-signal-success' />
            <p> Available storage</p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='ml-2 size-3 rounded-full bg-signal-danger' />
            <p> Occupied storage</p>
          </div>
        </NoddiBasicCard>
        <div className='flex gap-4'>
          <NoddiButton startIcon='Minus' variant='secondary' onPress={closeAllStorageAreas}>
            Collapse All
          </NoddiButton>
          <NoddiButton startIcon='Plus' variant='secondary' onPress={expandAllStorageAreas}>
            Expand All
          </NoddiButton>
          <NoddiButton
            onPress={() => {
              setIsCreateStorageAreaOpen(true);
            }}
          >
            Add storage area
          </NoddiButton>
        </div>
      </div>

      <StorageAreaCreateDrawer
        storageFacilityId={storageFacilityId}
        isCreateStorageAreaOpen={isCreateStorageAreaOpen}
        setIsCreateStorageAreaOpen={setIsCreateStorageAreaOpen}
      />
      {storageAreaToEdit && <StorageAreaDrawer storageArea={storageAreaToEdit} setStorageArea={setStorageAreaToEdit} />}
      {storageUnit && <StorageUnitDrawer storageUnitId={storageUnit.id} setStorageUnit={setStorageUnit} />}

      <div className='mt-8 flex flex-col gap-8'>
        {storageAreas.map((storageArea) => {
          const isExpanded = expandedStorageAreas[storageArea.id] || false;
          const sortedStorageUnits = storageArea.storageUnits.toSorted((a, b) => collator.compare(a.name, b.name));
          const visibleStorageUnits = isExpanded
            ? sortedStorageUnits
            : sortedStorageUnits.slice(0, defaultNumberToShow);

          return (
            <NoddiBasicCard className='flex flex-col gap-6' key={storageArea.id}>
              <div className='flex items-center justify-between gap-2'>
                <h2 className='font-bold text-5'>{storageArea.name}</h2>
                <div className='flex items-center gap-2'>
                  {storageArea.storageUnits.length > defaultNumberToShow && (
                    <NoddiButton
                      size='small'
                      variant='secondary'
                      startIcon={isExpanded ? 'Minus' : 'Plus'}
                      onPress={() => toggleExpandStorageArea(storageArea.id)}
                    >
                      {isExpanded ? 'Collapse' : 'Expand'}
                    </NoddiButton>
                  )}
                  <NoddiButton
                    startIcon='Edit'
                    size='small'
                    variant='secondary'
                    onPress={() => setStorageAreaToEdit(storageArea)}
                  >
                    Edit
                  </NoddiButton>
                </div>
              </div>
              <div className='grid grid-cols-2 gap-2 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-8'>
                {visibleStorageUnits.map((unit) => (
                  <NoddiButton
                    key={unit.id}
                    className='w-full'
                    variant={unit.isOccupied ? 'destructive' : 'success'}
                    onPress={() => setStorageUnit(unit)}
                  >
                    {unit.name}
                  </NoddiButton>
                ))}
              </div>
            </NoddiBasicCard>
          );
        })}
      </div>
    </>
  );
};
