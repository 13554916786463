import { StorageObjectV2 } from '../storageObject';
import { StorageArea } from './storageArea';

export const StorageUnitTypes = {
  STORAGE: 'STORAGE',
  LOADING_DOCK: 'LOADING_DOCK'
} as const;

export enum StoreUnitOccupancyStatus {
  OCCUPIED = 'occupied',
  UNOCCUPIED = 'unoccupied'
}

export type StorageUnitValueType = keyof typeof StorageUnitTypes;

export type StorageUnitType = {
  value: StorageUnitValueType;
  label: string;
};

export type BaseStorageUnit = {
  name: string;
};

export type StorageUnit = BaseStorageUnit & {
  id: number;
  unitType: StorageUnitType;
  fullName: string;
  storageArea: StorageArea;

  storageObjectGroups: StorageObjectV2[];
};

export type StorageUnitListItem = {
  id: number;
  name: string;
  storageAreaName: string;
};

export type StorageUnitOverviewType = {
  id: number;
  name: string;
  isOccupied: boolean;
};

export type StorageUnitCreateInput = BaseStorageUnit & {
  unitType: StorageUnitValueType;
  storageAreaId: number;
};

export type StorageUnitUpdateInput = BaseStorageUnit & {
  id: number;
  unitType?: StorageUnitValueType;
  storageAreaId?: number;
};

export type StorageUnitInput = {
  occupancyStatus?: StoreUnitOccupancyStatus[];
  storageFacilityId: number;
};
