import { ThemeProvider } from '@mui/material/styles';
import { noddiAsync } from 'noddi-async';
import { AuthProvider, NoddiLocalizationProvider, useAuthContext, UserRoles } from 'noddi-provider';
import { APIBanner, createTheme, LoginScreen, NbFallback, NoddiCircularLoader, ToastProvider } from 'noddi-ui';
import queryString from 'query-string';
import { Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import * as Sentry from '@sentry/react';
import { facilityRoutes } from 'noddi-util';
import { HomePrivateLayout } from './layout/HomePrivateLayout';
import { CreateStorageObject } from './Pages/CreateStorageObject';
import { CarWheelSetsInventory } from './Pages/Inventory/CarWheelSets';
import CarWheelSetInInventory from './Pages/Inventory/CarWheelSets/[id]';
import { LoadingDockPreparation } from './Pages/LoadingDockPreparation';
import { PrintLabelsStorageUnit } from './Pages/PrintLabelsStorageUnit';
import { PrintLabelsTireHotelRoute } from './Pages/PrintLabelsTireHotelRoute';
import { StorageOverview } from './Pages/StorageOverview';

const ROLE_REQUIRED_TO_ACCESS_APP = UserRoles.isStaff;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  const theme = createTheme();
  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary
      fallback={<NbFallback />}
      onError={(err, componentStack) => {
        Sentry.captureException(componentStack, {
          level: 'error',
          extra: {
            error: err
          }
        });
      }}
    >
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify
        }}
      >
        <NoddiServerContext>
          <AuthProvider requiredLoginLevels={[ROLE_REQUIRED_TO_ACCESS_APP]}>
            <ThemeProvider theme={theme}>
              <ToastProvider>
                <NoddiLocalizationProvider>
                  <Router />
                  <APIBanner />
                </NoddiLocalizationProvider>
              </ToastProvider>
            </ThemeProvider>
          </AuthProvider>
        </NoddiServerContext>
      </QueryParamProvider>
    </Sentry.ErrorBoundary>
  );
}

const Router = () => {
  const { isTokenLoginLoading } = useAuthContext();

  if (isTokenLoginLoading) {
    return (
      <div className='flex min-h-screen justify-center bg-pint-to-lighterPint-gradient'>
        <NoddiCircularLoader />
      </div>
    );
  }
  return (
    <SentryRoutes>
      <Route
        path={facilityRoutes.login.getBasePath()}
        element={<LoginScreen requiredLoginLevel={ROLE_REQUIRED_TO_ACCESS_APP} />}
      />
      <Route path='/' element={<HomePrivateLayout />}>
        <Route path='/' element={<CreateStorageObject />} />

        <Route path={facilityRoutes.createStorageObject.getBasePath()} element={<CreateStorageObject />} />
        <Route path={facilityRoutes.storageOverview.getBasePath()} element={<StorageOverview />} />
        <Route path={facilityRoutes.inventoryCarWheelSets.getBasePath()} element={<CarWheelSetsInventory />} />
        <Route path={facilityRoutes.printForTireHotelRoute.getBasePath()} element={<PrintLabelsTireHotelRoute />} />
        <Route path={facilityRoutes.loadingDockPreparation.getBasePath()} element={<LoadingDockPreparation />} />
        <Route path={facilityRoutes.storageObjectInInventory.getBasePath()} element={<CarWheelSetInInventory />} />
        <Route path={facilityRoutes.printForStorageUnit.getBasePath()} element={<PrintLabelsStorageUnit />} />
      </Route>
    </SentryRoutes>
  );
};

export default App;
