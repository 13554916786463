import { CarWheelSetForLabelPrint, RouteInPickingList } from 'noddi-async/src/types/workerapp/routeLabels';
import { NoddiButton, noddiColors, NoddiIcon } from 'noddi-ui-common';
import { DateFormats, noddiFormatDate } from 'noddi-util';
import { useRef } from 'react';
import QRCode, { QRCodeProps } from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';

const BaseQRCodeDisplay = ({ value, size = 256, bgColor = 'white', fgColor = 'black', level = 'L' }: QRCodeProps) => {
  return <QRCode value={value} size={size} bgColor={bgColor} fgColor={fgColor} level={level} />;
};

const numberOfLabelsPerBooking = 4;

type PrintLabelItem = {
  route?: RouteInPickingList;
  data: (CarWheelSetForLabelPrint & { url: string })[];
};

export const PrintLabelItem = ({ route, data }: PrintLabelItem) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const numberOfCars = data.length;

  return (
    <>
      <div className='flex h-fit flex-col gap-12 rounded-lg bg-primary-purple px-8 py-6 text-primary-white'>
        <div className='flex justify-between'>
          {route && (
            <div className='flex flex-col gap-2'>
              {route.serviceWorkers.map((serviceWorker) => (
                <h2 key={serviceWorker.id} className='font-bold'>
                  {serviceWorker.user.name}
                </h2>
              ))}
              <div className='flex gap-2'>
                <NoddiIcon color={noddiColors.primary.white} name='Calendar' size='medium' />
                {`${noddiFormatDate(route?.startsAt, DateFormats.HOURS)} - ${noddiFormatDate(route?.endsAt, DateFormats.HOURS)}`}
              </div>
            </div>
          )}

          <div className='flex gap-2'>
            <NoddiIcon color={noddiColors.primary.white} name='Car' size='medium' />
            <p>
              {numberOfCars} {route ? 'Cars on route ' : 'Cars'}
            </p>
          </div>
        </div>
        <div className='flex items-center gap-6'>
          <NoddiButton disabled={numberOfCars === 0} variant='secondary' onPress={handlePrint}>
            Print
          </NoddiButton>
          <p> {`${numberOfCars * numberOfLabelsPerBooking} ${'labels'}`} </p>
        </div>
      </div>
      <div className='hidden'>
        <div ref={componentRef}>
          <div>
            {data.map(({ car: { licensePlateNumber, carGeneration }, id, url, type: { label }, userGroupName }) => (
              <div key={id}>
                {Array.from({ length: numberOfLabelsPerBooking }).map((_, index) => (
                  <div key={`${id}-${index}`} className='flex h-[235px] items-center justify-center gap-6'>
                    <BaseQRCodeDisplay size={160} value={url} />
                    <div className='flex w-5/12 flex-col gap-2'>
                      <h3 className='font-bold'>{licensePlateNumber}</h3>
                      <div className='flex flex-col gap-1'>
                        <p className='font-bold'>{`${carGeneration.make.name} - ${carGeneration.model.name}`}</p>
                        <p>{label}</p>
                        <p>{userGroupName}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
