import { facilityRoutes } from 'noddi-util';

export const getNavItems = () => [
  {
    items: [
      {
        path: facilityRoutes.loadingDockPreparation.getPath(),
        title: 'Loading dock preparation'
      }
    ]
  },
  {
    items: [
      {
        title: 'Print labels',
        items: [
          {
            path: facilityRoutes.createStorageObject.getPath(),
            title: 'Wheel set'
          },
          // Uncomment this when tier hotel route print labels is ready
          // {
          //   path: facilityRoutes.printForTireHotelRoute.getPath(),
          //   title: 'Tire hotel route'
          // },
          {
            path: facilityRoutes.printForStorageUnit.getPath(),
            title: 'Storage area'
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        path: facilityRoutes.storageOverview.getPath(),
        title: 'Storage overview'
      }
    ]
  },
  {
    items: [
      {
        title: 'Inventory',
        items: [
          {
            title: 'Car wheel sets',
            path: facilityRoutes.inventoryCarWheelSets.getPath()
          }
        ]
      }
    ]
  }
];
