const appRoutesKeys = {
  home: '/',
  storageOverview: 'storageOverview',
  inventoryCarWheelSets: 'inventoryCarWheelSets',
  loadingDockPreparation: 'loadingDockPreparation',
  login: 'login',
  profile: 'profile',
  createStorageObject: 'createStorageObject',
  printForStorageUnit: 'printForStorageUnit',
  printForTireHotelRoute: 'printForTireHotelRoute',
  storageObjectInInventory: 'storageObjectInInventory'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

// Define the parameters for the routes
interface PathConfig {
  printLabelForStorageObject: { identifier: string };
  storageObjectInInventory: { identifier: string };
}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
};
export const facilityRoutes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  profile: {
    getPath: () => '/profile',
    getBasePath: () => '/profile'
  },
  home: {
    getPath: () => '/',
    getBasePath: () => '/'
  },
  storageOverview: {
    getPath: () => '/storage-overview',
    getBasePath: () => '/storage-overview'
  },
  printForStorageUnit: {
    getPath: () => '/print-for-storage-unit',
    getBasePath: () => '/print-for-storage-unit'
  },
  inventoryCarWheelSets: {
    getPath: () => '/inventory/car-wheel-sets',
    getBasePath: () => '/inventory/car-wheel-sets'
  },
  loadingDockPreparation: {
    getPath: () => '/loading-dock-preparation',
    getBasePath: () => '/loading-dock-preparation'
  },
  printForTireHotelRoute: {
    getPath: () => '/print-for-tire-hotel-route',
    getBasePath: () => '/print-for-tire-hotel-route'
  },
  createStorageObject: {
    getPath: () => '/create-storage-object',
    getBasePath: () => '/create-storage-object'
  },

  storageObjectInInventory: {
    getPath: (data) => `/inventory/car-wheel-sets/${data.identifier}`,
    getBasePath: () => '/inventory/car-wheel-sets/:identifier'
  }
};
