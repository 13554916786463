import { Form, Formik } from 'formik';
import { noddiAsync, URLKeys } from 'noddi-async';
import { StorageUnitTypes, StorageUnitValueType } from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import {
  ApiErrorMessage,
  FieldWrapper,
  LoadingScreen,
  NoddiFormAutocomplete,
  NoddiFormTextInput,
  useNoddiToast
} from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  unitType: Yup.object().shape({
    value: Yup.mixed<StorageUnitValueType>()
      .oneOf(Object.keys(StorageUnitTypes) as StorageUnitValueType[])
      .required('Required'),
    label: Yup.string().required('Required')
  })
});

type Props = {
  storageAreaId: number;
  onCreated: () => void;
};

export const StorageUnitCreateForm = ({ storageAreaId, onCreated }: Props) => {
  const { noddiToast } = useNoddiToast();

  const {
    data: storageUnitTypes,
    error: storageUnitTypesError,
    isPending: isStorageUnitTypesPending
  } = noddiAsync.useGet({
    type: URLKeys.getStorageUnitTypes
  });

  const { mutateAsync: createStorageUnit, isPending: isCreateStorageUnitPending } = noddiAsync.usePost({
    type: URLKeys.postStorageUnit,
    queryConfig: {
      onSuccess: async () => {
        onCreated();
        invalidateQueryKey({ urlKey: URLKeys.getStorageAreasOverview });
        noddiToast.success('Storage unit created');
      },
      onError: async () => {
        noddiToast.error('Failed to create storage unit');
      }
    }
  });

  if (storageUnitTypesError) {
    return <ApiErrorMessage error={[storageUnitTypesError]} />;
  }
  if (isStorageUnitTypesPending) {
    return <LoadingScreen />;
  }

  const defaultStorageUnitOption = storageUnitTypes.find((type) => type.value === StorageUnitTypes.STORAGE);

  return (
    <Formik
      initialValues={{
        name: '',
        unitType: defaultStorageUnitOption
      }}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={async (values) => {
        await createStorageUnit({
          name: values.name,
          unitType: values.unitType!.value,
          storageAreaId
        });
      }}
    >
      {({ isValid, submitForm }) => (
        <Form>
          <FieldWrapper>
            <NoddiFormTextInput name='name' label='Name' />
            <NoddiFormAutocomplete
              name='unitType'
              label='Type'
              options={storageUnitTypes}
              defaultOption={defaultStorageUnitOption}
            />

            <NoddiButton
              variant='primary'
              onPress={submitForm}
              loading={isCreateStorageUnitPending}
              disabled={!isValid}
            >
              Create
            </NoddiButton>
          </FieldWrapper>
        </Form>
      )}
    </Formik>
  );
};
