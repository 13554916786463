import { NoddiAsyncError } from 'noddi-async/src/types';
import { NoddiFeedbackBox, getRawNoddiApiErrors, useCaptureSentryException } from 'noddi-ui';

export function ApiErrorMessageAdmin({ error }: { error: NoddiAsyncError | (NoddiAsyncError | null)[] }) {
  useCaptureSentryException(error);

  const customErrorMessage = getRawNoddiApiErrors(error);

  return (
    <div className='mt-2'>
      <NoddiFeedbackBox variant='error' description={customErrorMessage} />
    </div>
  );
}
