import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

interface StorageFacilityStore {
  clearStore: () => void;

  // For now only relevant for storageFacility bookings
  storageFacility: { label: string; storageFacilityId: number };
  setStorageFacility: (storageFacility: { label: string; storageFacilityId: number }) => void;
}
// https://zustand.docs.pmnd.rs/guides/initialize-state-with-props#store-creator-with-createstore
// I guess we can do like example above, and init store with props from authProvider or something?
const initialStateLabels = {
  storageFacility: { label: 'Ramstadsletta', storageFacilityId: 1 }
};

const useStorageFacilityStore = create<StorageFacilityStore>()(
  persist(
    devtools((set) => ({
      ...initialStateLabels,
      clearStore: () => set(initialStateLabels),

      setStorageFacility: (storageFacility) =>
        set({
          storageFacility
        })
    })),
    {
      name: 'storageFacility',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

// Getters
export const useStorageFacility = () => useStorageFacilityStore((state) => state.storageFacility);

// Actions
export const useStorageFacilityActions = () => {
  const clearStore = useStorageFacilityStore((state) => state.clearStore);
  const setStorageFacility = useStorageFacilityStore((state) => state.setStorageFacility);

  return {
    clearStore,
    setStorageFacility
  };
};
