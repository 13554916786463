import { NoddiContainer } from 'noddi-ui';
import { StorageAreaList } from './StorageAreaList';

export const StorageOverview = () => {
  return (
    <NoddiContainer header='Storage overview' description='Overview of free and occupied spots in each warehouse.'>
      <StorageAreaList />
    </NoddiContainer>
  );
};
