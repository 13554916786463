import { debounce } from '@mui/material/utils';
import { noddiAsync, URLKeys } from 'noddi-async';
import {
  ErrorPage,
  InfoPage,
  LoadingScreen,
  NoddiContainer,
  NoddiSearchBar,
  NoddiTablePagination,
  usePaginationQueryParam
} from 'noddi-ui';
import { useEffect, useState } from 'react';
import { CarWheelSetCard } from './Card';

const STORAGE_FACILITY_ID = 1;

export const CarWheelSetsInventory = () => {
  const {
    page,
    setPage,
    pageSize,
    setPageSize,
    search: querySearch,
    setSearch: setQuerySearch
  } = usePaginationQueryParam();

  const [search, setSearch] = useState(querySearch);

  useEffect(() => {
    setPage(1);
    setQuerySearch(search);
  }, [search, setPage, setQuerySearch]);

  const debouncedSearch = debounce((newSearch: string) => {
    setSearch(newSearch);
  }, 300);

  const {
    data: carWheelSets,
    isPending: isCarWheelSetsPending,
    error: carWheelSetsError
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedCarWheelSetsForFacility,
    input: { storageFacilityId: STORAGE_FACILITY_ID, page, pageSize, search }
  });

  if (carWheelSetsError) {
    return <ErrorPage apiError={carWheelSetsError} />;
  }

  return (
    <NoddiContainer header='Inventory' description='Overview of the car wheel sets in the facility'>
      <NoddiSearchBar
        handleChange={(e) => debouncedSearch(e.target.value)}
        placeholder='Search for car or user'
        defaultValue={search}
      />
      {isCarWheelSetsPending ? (
        <LoadingScreen />
      ) : (
        <>
          {!carWheelSets.results.length && (
            <InfoPage
              title='No car wheel sets in the inventory matches the search'
              message='Add a new car wheel set to the inventory or try a different search.'
            />
          )}

          <div className='mt-4 flex flex-col gap-3'>
            {carWheelSets.results.map((carWheelSet) => (
              <CarWheelSetCard
                key={carWheelSet.carWheelSetId}
                carWheelSet={carWheelSet}
                identifier={carWheelSet.storageObjectGroup.identifier}
              />
            ))}
          </div>
          <NoddiTablePagination
            translations={{ rowsPerPage: 'Rows per page', of: 'of' }}
            page={page}
            setPage={setPage}
            setRowsPerPage={setPageSize}
            rowsPerPage={pageSize}
            count={carWheelSets.count ?? 0}
          />
        </>
      )}
    </NoddiContainer>
  );
};
