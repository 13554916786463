import groupBy from 'lodash/groupBy';
import { noddiAsync, URLKeys } from 'noddi-async';
import { CarWheelSetToPickUp } from 'noddi-async/src/types';
import { ErrorPage, FixedBottomBarContainer, LoadingScreen, NoddiBasicCard } from 'noddi-ui';
import { NoddiButton, noddiColors, NoddiDummyButton, NoddiIcon } from 'noddi-ui-common';
import { DateFormats, noddiFormatDate } from 'noddi-util';
import { useNavigate } from 'react-router-dom';

type PickingListProps = {
  date: Date;
  showPersonalList: boolean;
  selectedWorkerId: number | undefined;
};

export const PickingListView = ({ date, showPersonalList, selectedWorkerId }: PickingListProps) => {
  const dateString = noddiFormatDate(date, DateFormats.DASHED_DATE_ISO_8601);
  const showGlobalPickingList = !showPersonalList || !selectedWorkerId;

  const {
    refetch: refetchCarWheelSets,
    isPending: isCarWheelSetsPending,
    data: carWheelSets,
    error: carWheelSetsError,
    isRefetching: isCarWheelSetsRefreshing
  } = noddiAsync.useGet({
    type: URLKeys.getCarWheelSetsToPickUp,
    input: {
      date: dateString,
      serviceWorkerIds: showGlobalPickingList ? [] : [selectedWorkerId]
    }
  });

  if (carWheelSetsError) {
    return <ErrorPage apiError={carWheelSetsError} />;
  }

  if (isCarWheelSetsPending) {
    return <LoadingScreen />;
  }

  const carWheelSetsAtUnit = carWheelSets.filter((carWheelSet) => carWheelSet.storageObjectGroup?.latestEvent?.toUnit);

  const carWheelSetsInTransit = carWheelSets.filter(
    (carWheelSet) => carWheelSet.storageObjectGroup?.latestEvent?.isInTransit
  );

  const carWheelSetsGroupedByStorageLocation = groupBy(
    carWheelSetsAtUnit,
    (carWheelSet) =>
      `${carWheelSet.storageObjectGroup?.latestEvent?.toUnit?.storageAreaName} - ${carWheelSet.storageObjectGroup?.latestEvent?.toUnit?.name}`
  );

  const numberOfWheelsAtLoadingDock = carWheelSetsAtUnit.filter(
    (carWheelSet) => carWheelSet.storageObjectGroup?.latestEvent?.isAtLoadingDock
  ).length;

  return (
    <>
      <div className='mb-20 flex flex-col gap-4 px-3 pb-4 pt-3'>
        <div className='mt-5 flex flex-col gap-6'>
          {carWheelSetsGroupedByStorageLocation && (
            <div className='flex flex-col gap-6'>
              {Object.keys(carWheelSetsGroupedByStorageLocation).map((storageAreaName) => {
                const carWheelSets = carWheelSetsGroupedByStorageLocation[storageAreaName]!;
                return (
                  <>
                    <div className='flex items-center gap-2'>
                      <div>
                        <NoddiIcon name='LocationPin' />
                      </div>
                      <h2 className='font-bold text-5'>{storageAreaName}</h2>
                    </div>

                    {carWheelSets.map((carWheelSet) => (
                      <CarWheelSetCard key={carWheelSet.id} carWheelSet={carWheelSet} isInTransit={false} />
                    ))}
                  </>
                );
              })}
            </div>
          )}

          {carWheelSetsInTransit.length > 0 && (
            <div className='flex flex-col gap-6'>
              <h2 className='font-bold text-5'>In transit</h2>
              {carWheelSetsInTransit.map((carWheelSet) => (
                <CarWheelSetCard key={carWheelSet.id} carWheelSet={carWheelSet} isInTransit />
              ))}
            </div>
          )}
        </div>
      </div>

      {carWheelSets.length > 0 && (
        <FixedBottomBarContainer>
          <div className='flex w-full justify-center gap-6 p-3'>
            <div className='flex items-center gap-1'>
              <NoddiIcon name='WheelAngle' />
              <p>
                {numberOfWheelsAtLoadingDock} / {carWheelSets.length} at loading dock
              </p>
            </div>
            <NoddiButton
              onPress={() => {
                refetchCarWheelSets();
              }}
              loading={isCarWheelSetsPending || isCarWheelSetsRefreshing}
            >
              Refresh
            </NoddiButton>
          </div>
        </FixedBottomBarContainer>
      )}
    </>
  );
};

const CarWheelSetCard = ({ carWheelSet, isInTransit }: { carWheelSet: CarWheelSetToPickUp; isInTransit: boolean }) => {
  const navigate = useNavigate();

  return (
    <NoddiBasicCard
      onClick={() => {
        navigate(`/inventory/car-wheel-sets/${carWheelSet.storageObjectGroup.identifier}`);
      }}
    >
      <div className='flex gap-2'>
        <div>
          <NoddiIcon name='User' />
        </div>
        <h3>{carWheelSet.userGroup.name}</h3>
      </div>

      <div className='flex gap-2'>
        <div>
          <NoddiIcon name='Car' />
        </div>
        <h3>
          {carWheelSet.car.make} - {carWheelSet.car.model} - {carWheelSet.car.licensePlate.number}
        </h3>
      </div>

      <div className='flex gap-2'>
        <div>
          <NoddiIcon name='WheelAngle' />
        </div>
        <h3>{carWheelSet.type.label}</h3>
      </div>

      {carWheelSet.route && (
        <div className='mt-2'>
          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon name='UserCircle' />
            </div>
            <p>
              {carWheelSet.route.serviceWorkers
                ?.map((worker) => `${worker.user.firstName} ${worker.user.lastName}`)
                .join(', ')}
            </p>
          </div>

          <div className='flex items-center gap-2'>
            <div>
              <NoddiIcon name='Calendar' />
            </div>
            <p>{`${noddiFormatDate(carWheelSet.route.startsAt, DateFormats.TIME)} - ${noddiFormatDate(carWheelSet.route.endsAt, DateFormats.TIME)}`}</p>
          </div>
        </div>
      )}

      {isInTransit && (
        <div className='mt-2 flex gap-2'>
          <div>
            <NoddiIcon name='Info' />
          </div>
          <h3>
            Moved by {carWheelSet.storageObjectGroup?.latestEvent?.createdBy.firstName}{' '}
            {carWheelSet.storageObjectGroup?.latestEvent?.createdBy.lastName} at{' '}
            {noddiFormatDate(carWheelSet.storageObjectGroup?.latestEvent?.createdAt, DateFormats.DASHED_DATE_TIME)}
          </h3>
        </div>
      )}

      <div className='mt-2 flex items-center gap-2'>
        {carWheelSet.storageObjectGroup?.latestEvent?.isAtLoadingDock ? (
          <>
            <NoddiIcon color={noddiColors.signal.success} name='Check' />
            <p className='text-signal-success'>At loading dock</p>
          </>
        ) : (
          <>
            <NoddiIcon color={noddiColors.signal.danger} name='Cross' />
            <p className='text-signal-danger'>Not at loading dock</p>
          </>
        )}
      </div>

      <div className='flex justify-end'>
        <NoddiDummyButton endIcon='ArrowRight' variant='link'>
          Details
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};
