import { Form, Formik } from 'formik';
import { noddiAsync, URLKeys } from 'noddi-async';
import { StorageAreaOverview } from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import {
  FieldWrapper,
  NoddiBasicCard,
  NoddiConfirmationDialog,
  NoddiDrawer,
  NoddiFeedbackBox,
  NoddiFormTextInput,
  useNoddiToast
} from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import { useState } from 'react';
import * as Yup from 'yup';
import { StorageUnitCreateForm } from './StorageUnitCreateForm';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

type Props = {
  storageArea: StorageAreaOverview;
  setStorageArea: (storageArea: StorageAreaOverview | null) => void;
};

export const StorageAreaDrawer = ({ storageArea, setStorageArea }: Props) => {
  const { noddiToast } = useNoddiToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutateAsync: patchStorageArea, isPending: isPatchingStorageArea } = noddiAsync.usePatch({
    type: URLKeys.patchStorageArea,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({ urlKey: URLKeys.getStorageAreasOverview });
        noddiToast.success('Storage area updated');
        setStorageArea(null);
      },
      onError: async () => {
        noddiToast.error('Failed to update storage area');
      }
    }
  });

  const { mutateAsync: deleteStorageArea, isPending: isDeletingStorageArea } = noddiAsync.useDelete({
    type: URLKeys.deleteStorageArea,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({ urlKey: URLKeys.getStorageAreasOverview });
        noddiToast.success('Storage area deleted');
        setStorageArea(null);
      },
      onError: async () => {
        noddiToast.error('Failed to delete storage area');
      }
    }
  });

  const numberOfStorageUnits = storageArea.storageUnits.length;
  const hasStorageUnits = numberOfStorageUnits > 0;
  const isStorageAreaOccupied = storageArea.storageUnits.some((unit) => unit.isOccupied);

  return (
    <NoddiDrawer isOpen={!!storageArea} onClose={() => setStorageArea(null)}>
      <NoddiConfirmationDialog
        title='Are you sure you want to delete this storage area?'
        onConfirm={async () => {
          await deleteStorageArea({ id: storageArea.id });
        }}
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        isLoading={isDeletingStorageArea}
        cancelText='Cancel'
        confirmText='Delete'
      >
        {hasStorageUnits && (
          <p className='mt-2'>
            Deleting the storage area will also delete all {numberOfStorageUnits} storage units within it.
          </p>
        )}
      </NoddiConfirmationDialog>

      <Formik
        initialValues={{
          name: storageArea.name
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values) => {
          await patchStorageArea({
            id: storageArea.id,
            name: values.name
          });
        }}
      >
        {({ isValid, submitForm }) => (
          <Form>
            <FieldWrapper>
              <NoddiFormTextInput name='name' label='Name' />
              <div className='flex gap-2'>
                <NoddiButton variant='success' onPress={submitForm} loading={isPatchingStorageArea} disabled={!isValid}>
                  Save
                </NoddiButton>
                <NoddiButton
                  variant='destructive'
                  onPress={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                  loading={isDeletingStorageArea}
                  disabled={isStorageAreaOccupied}
                >
                  Delete
                </NoddiButton>
              </div>
              {isStorageAreaOccupied && (
                <NoddiFeedbackBox
                  variant='info'
                  description='This storage area cannot be deleted because it contains storage units that is not empty.'
                />
              )}
            </FieldWrapper>
          </Form>
        )}
      </Formik>

      <NoddiBasicCard className='mt-5 items-end bg-secondary-lightPurple'>
        <p className='mb-2 font-bold'>Add storage unit</p>
        <StorageUnitCreateForm storageAreaId={storageArea.id} onCreated={() => setStorageArea(null)} />
      </NoddiBasicCard>
    </NoddiDrawer>
  );
};
