import { CarWheelSetAtFacility } from 'noddi-async/src/types';
import { NoddiBasicCard } from 'noddi-ui';
import { NoddiDummyButton, NoddiIcon } from 'noddi-ui-common';
import { facilityRoutes } from 'noddi-util';
import { useNavigate } from 'react-router-dom';
import { getWheelSetTypeTextProperties } from './utils';

export const CarWheelSetCard = ({
  carWheelSet,
  identifier
}: {
  carWheelSet: CarWheelSetAtFacility;
  identifier: string;
}) => {
  const navigate = useNavigate();

  const { emoji, value } = getWheelSetTypeTextProperties(carWheelSet.carWheelSetType);

  const latestEvent = carWheelSet.storageObjectGroup.latestEvent;

  const currentStorageUnitLabel = latestEvent?.toUnit
    ? `${latestEvent.toUnit.storageAreaName} - ${latestEvent.toUnit.name}`
    : 'In transit';

  return (
    <NoddiBasicCard
      onClick={() => navigate(facilityRoutes.storageObjectInInventory.getPath({ identifier }))}
      className='flex justify-between gap-4'
    >
      <div className='grid w-full grid-cols-2 gap-4 lg:grid-cols-4'>
        <div className='flex gap-2'>
          <NoddiIcon name='UserCircle' size='medium' />
          <span>{carWheelSet.carOwner.name}</span>
        </div>
        <div className='flex gap-2'>
          <NoddiIcon name='Car' size='medium' />
          <span>
            {carWheelSet.carMake} {carWheelSet.carModel} - {carWheelSet.carLicensePlate.number}
          </span>
        </div>

        <div className='flex gap-2'>
          <NoddiIcon name='WheelAngle' size='medium' />
          <div className='flex gap-1'>
            <span>{value}</span>
            <span>{emoji}</span>
          </div>
        </div>
        <div className='flex gap-2'>
          <NoddiIcon name='LocationPin' size='medium' />
          <span>{currentStorageUnitLabel}</span>
        </div>
      </div>

      <div className='flex items-end justify-end'>
        <NoddiDummyButton endIcon='ArrowRight' variant='link' className='-mb-2 -mr-3'>
          Details
        </NoddiDummyButton>
      </div>
    </NoddiBasicCard>
  );
};
