import { Box, Drawer } from '@mui/material';
import { ReactNode } from 'react';

import { useIsMobile } from '../../hooks';
import { NoddiIconButton } from '../../molecules';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  anchor?: 'top' | 'left' | 'bottom' | 'right';
  width?: number | string;
}
export function NoddiDrawer({ isOpen, onClose, children, anchor = 'right', width }: Props) {
  const isMobile = useIsMobile();
  const defaultWidth = isMobile ? 300 : 400;

  return (
    <Drawer anchor={anchor} open={isOpen} onClose={onClose}>
      <Box sx={{ width: width ? width : anchor === 'top' || anchor === 'bottom' ? 'auto' : defaultWidth }} padding={2}>
        <div className='flex w-full justify-end'>
          <NoddiIconButton variant='ghost' onClick={onClose} iconSize='large' iconName='Cross' />
        </div>
        {children}
      </Box>
    </Drawer>
  );
}
