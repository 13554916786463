import { Form, Formik } from 'formik';
import { noddiAsync, URLKeys } from 'noddi-async';
import {
  CarWheelSetsStorageObject,
  StorageObjectV2,
  StorageUnit,
  StorageUnitOverviewType,
  StorageUnitType,
  StorageUnitTypes,
  StorageUnitValueType
} from 'noddi-async/src/types';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import {
  ApiErrorMessage,
  FieldWrapper,
  LoadingScreen,
  NoddiBasicCard,
  NoddiConfirmationDialog,
  NoddiDrawer,
  NoddiFeedbackBox,
  NoddiFormAutocomplete,
  NoddiFormTextInput,
  useNoddiToast
} from 'noddi-ui';
import { NoddiButton, NoddiDummyButton, NoddiIcon } from 'noddi-ui-common';
import { facilityRoutes } from 'noddi-util';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getWheelSetTypeTexts } from './utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  unitType: Yup.object().shape({
    value: Yup.mixed<StorageUnitValueType>()
      .oneOf(Object.keys(StorageUnitTypes) as StorageUnitValueType[])
      .required('Required'),
    label: Yup.string().required('Required')
  })
});

type Props = {
  storageUnitId: number;
  setStorageUnit: (storageUnit: StorageUnitOverviewType | null) => void;
};

export const StorageUnitDrawer = ({ storageUnitId, setStorageUnit }: Props) => {
  const {
    data: storageUnitTypes,
    error: storageUnitTypesError,
    isPending: isStorageUnitTypesPending
  } = noddiAsync.useGet({
    type: URLKeys.getStorageUnitTypes
  });

  const {
    data: storageUnitDetails,
    error: storageUnitError,
    isPending: isStorageUnitPending
  } = noddiAsync.useGet({
    type: URLKeys.getStorageUnit,
    input: { id: storageUnitId }
  });

  if (storageUnitError || storageUnitTypesError) {
    return <ApiErrorMessage error={[storageUnitError, storageUnitTypesError]} />;
  }
  const isLoading = isStorageUnitTypesPending || isStorageUnitPending;

  return (
    <NoddiDrawer isOpen={!!storageUnitId} onClose={() => setStorageUnit(null)}>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <StorageUnitDrawerContent
          storageUnitDetails={storageUnitDetails}
          setStorageUnit={setStorageUnit}
          storageUnitTypes={storageUnitTypes}
        />
      )}
    </NoddiDrawer>
  );
};

const StorageUnitDrawerContent = ({
  storageUnitDetails,
  setStorageUnit,
  storageUnitTypes
}: {
  storageUnitDetails: StorageUnit;
  setStorageUnit: (storageUnit: StorageUnitOverviewType | null) => void;
  storageUnitTypes: StorageUnitType[];
}) => {
  const { noddiToast } = useNoddiToast();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { mutateAsync: patchStorageUnit, isPending: isPatchingStorageUnit } = noddiAsync.usePatch({
    type: URLKeys.patchStorageUnit,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({ urlKey: URLKeys.getStorageAreasOverview });
        invalidateQueryKey({ urlKey: URLKeys.getStorageUnit });
        noddiToast.success('Storage unit updated');
        setStorageUnit(null);
      },
      onError: async () => {
        noddiToast.error('Failed to update storage unit');
      }
    }
  });

  const { mutateAsync: deleteStorageUnit, isPending: isDeletingStorageUnit } = noddiAsync.useDelete({
    type: URLKeys.deleteStorageUnit,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryKey({ urlKey: URLKeys.getStorageAreasOverview });
        noddiToast.success('Storage unit deleted');
        setStorageUnit(null);
      },
      onError: async () => {
        noddiToast.error('Failed to delete storage unit');
      }
    }
  });

  const defaultStorageUnitOption = storageUnitDetails.unitType;
  const isStorageUnitOccupied = storageUnitDetails.storageObjectGroups.length > 0;

  return (
    <>
      <NoddiConfirmationDialog
        description='Are you sure you want to delete this storage unit?'
        onConfirm={async () => {
          await deleteStorageUnit({ id: storageUnitDetails.id });
        }}
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        isLoading={isDeletingStorageUnit}
        cancelText='No'
        confirmText='Yes'
      />

      <Formik
        initialValues={{
          name: storageUnitDetails.name,
          unitType: defaultStorageUnitOption
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values) => {
          await patchStorageUnit({
            id: storageUnitDetails.id,
            name: values.name,
            unitType: values.unitType.value
          });
        }}
      >
        {({ isValid, submitForm }) => (
          <Form>
            <FieldWrapper>
              <NoddiFormTextInput name='name' label='Name' />
              <NoddiFormAutocomplete
                name='unitType'
                label='Type'
                options={storageUnitTypes}
                defaultOption={defaultStorageUnitOption}
              />
              <div className='flex gap-2'>
                <NoddiButton variant='primary' onPress={submitForm} loading={isPatchingStorageUnit} disabled={!isValid}>
                  Save
                </NoddiButton>
                <NoddiButton
                  variant='destructive'
                  onPress={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                  loading={isDeletingStorageUnit}
                  disabled={isStorageUnitOccupied}
                >
                  Delete
                </NoddiButton>
              </div>

              {isStorageUnitOccupied && (
                <NoddiFeedbackBox
                  variant='info'
                  description='This storage unit cannot be deleted because it is not empty.'
                />
              )}
            </FieldWrapper>
          </Form>
        )}
      </Formik>

      <StorageObjectGroupsContent storageObjectGroups={storageUnitDetails.storageObjectGroups} />
    </>
  );
};

const CarWheelSetContent = ({ carWheelSet }: { carWheelSet: CarWheelSetsStorageObject }) => {
  const wheelSetTypeText = getWheelSetTypeTexts(carWheelSet.carWheelSetType);

  return (
    <>
      <div className='flex flex-row items-center gap-2'>
        <NoddiIcon name='User' />
        <p>{carWheelSet.carOwner.name}</p>
      </div>
      <div className='flex flex-row items-center gap-2'>
        <NoddiIcon name='Car' />
        <p>
          {carWheelSet.carMake} {carWheelSet.carModel} - {carWheelSet.carLicensePlate.number}
        </p>
      </div>
      <div className='flex flex-row items-center gap-2'>
        <NoddiIcon name='Wheel' />
        <p>{wheelSetTypeText} tires</p>
      </div>
    </>
  );
};

const StorageObjectGroupsContent = ({ storageObjectGroups }: { storageObjectGroups: StorageObjectV2[] }) => {
  const navigate = useNavigate();

  return (
    <div className='mt-5 grid grid-cols-1 gap-4'>
      {storageObjectGroups.length > 0 && <p className='font-bold'>Stored objects at this location:</p>}
      {storageObjectGroups.map((storageObjectGroup) => (
        <NoddiBasicCard
          key={storageObjectGroup.identifier}
          className='space-y-2 bg-secondary-lightPurple/50 text-secondary-black'
          onClick={() => {
            navigate(
              facilityRoutes.storageObjectInInventory.getPath({
                identifier: storageObjectGroup.identifier
              })
            );
          }}
        >
          {storageObjectGroup.carWheelSets.length > 0 && (
            <div className='space-y-2'>
              {storageObjectGroup.carWheelSets.map((carWheelSet) => (
                <CarWheelSetContent key={carWheelSet.carWheelSetId} carWheelSet={carWheelSet} />
              ))}
            </div>
          )}
          <div className='flex justify-end'>
            <NoddiDummyButton endIcon='ArrowRight' variant='link'>
              Inventory page
            </NoddiDummyButton>
          </div>
        </NoddiBasicCard>
      ))}
    </div>
  );
};
