import { NoddiContainer } from 'noddi-ui';
import { StorageAreaPrintingList } from './StorageAreaPrintingList';

export const PrintLabelsStorageUnit = () => {
  return (
    <NoddiContainer header='Storage area printing' description='Print labels for storage areas'>
      <StorageAreaPrintingList />
    </NoddiContainer>
  );
};
