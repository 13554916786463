import {
  CarWheelSetType,
  CarWheelSetTypes,
  LatestEventForStorageObject,
  StorageEventType
} from 'noddi-async/src/types';

import { DateFormats, noddiFormatDate } from 'noddi-util';

// These two not used for now, maybe use on detail page? :)
export const getLatestMovementLabel = ({ latestEvent }: { latestEvent: LatestEventForStorageObject }) => {
  const userString = `${latestEvent.createdBy.firstName} ${latestEvent.createdBy.lastName}`;

  switch (latestEvent.eventType) {
    case StorageEventType.PICKUP_FROM_CUSTOMER:
      return `Picked up from customer by ${userString}`;
    case StorageEventType.INTERNAL_PICKUP:
      return `Picked up by ${userString}`;
    case StorageEventType.INTERNAL_DELIVERY:
      return `Delivered by ${userString}`;
    default:
      return '';
  }
};

export const getLatestMovementTime = ({ latestEvent }: { latestEvent: LatestEventForStorageObject }) => {
  return noddiFormatDate(latestEvent.createdAt, DateFormats.DASHED_DATE_TIME);
};

export const getWheelSetTypeTextProperties = (type: CarWheelSetType) => {
  switch (type.value) {
    case CarWheelSetTypes.Summer:
      return { value: 'Summer', emoji: '☀️' };

    case CarWheelSetTypes.Winter:
      return { value: 'Winter', emoji: '❄️' };
    default:
      return { value: 'Unknown', emoji: '❓' };
  }
};
