import { Form, Formik } from 'formik';
import { noddiAsync, URLKeys } from 'noddi-async';
import { invalidateQueryKey } from 'noddi-async/src/utils';
import { ApiErrorMessage, FieldWrapper, LoadingScreen, NoddiDrawer, NoddiFormTextInput, useNoddiToast } from 'noddi-ui';
import { NoddiButton } from 'noddi-ui-common';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

type Props = {
  storageFacilityId: number;
  isCreateStorageAreaOpen: boolean;
  setIsCreateStorageAreaOpen: (isOpen: boolean) => void;
};

export const StorageAreaCreateDrawer = ({
  storageFacilityId,
  isCreateStorageAreaOpen,
  setIsCreateStorageAreaOpen
}: Props) => {
  const { noddiToast } = useNoddiToast();

  const {
    mutateAsync: createStorageArea,
    error: createStorageAreaError,
    isPending: isCreatingStorageArea
  } = noddiAsync.usePost({
    type: URLKeys.postStorageArea,
    queryConfig: {
      onSuccess: async () => {
        setIsCreateStorageAreaOpen(false);
        invalidateQueryKey({ urlKey: URLKeys.getStorageAreasOverview });
        noddiToast.success('Storage area created');
      },
      onError: async () => {
        noddiToast.error('Failed to create storage area');
      }
    }
  });

  if (createStorageAreaError) {
    return <ApiErrorMessage error={createStorageAreaError} />;
  }
  if (isCreatingStorageArea) {
    return <LoadingScreen />;
  }

  return (
    <NoddiDrawer isOpen={isCreateStorageAreaOpen} onClose={() => setIsCreateStorageAreaOpen(false)}>
      <Formik
        initialValues={{
          name: ''
        }}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values) => {
          await createStorageArea({
            name: values.name,
            storageFacilityId
          });
        }}
      >
        {({ isValid, submitForm }) => (
          <Form>
            <FieldWrapper>
              <NoddiFormTextInput name='name' label='Name' />

              <NoddiButton onPress={submitForm} loading={isCreatingStorageArea} disabled={!isValid}>
                Create
              </NoddiButton>
            </FieldWrapper>
          </Form>
        )}
      </Formik>
    </NoddiDrawer>
  );
};
